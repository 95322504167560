import { IProduct } from '../types/product'
import { HubConnection } from '@microsoft/signalr'
import { User } from '../entity/User'
import { SignalRMethods } from './lib/SignalRMethods'

export const subscribeToProductLogs = async (
    connection: HubConnection,
    products: IProduct[] = []
) => {
    const user = new User()

    if (user.isFullRightsManagerOrAbove()) {
        try {
            if (!products?.length) return

            const subscriptions = products?.map((product: IProduct) => {
                return connection.send(
                    SignalRMethods.LOG_SUBSCRIPTION,
                    product?.guid
                )
            })
            await Promise.allSettled(subscriptions)
            console.log('Subscription to products logs success')
        } catch (e) {
            console.error(e)
        }
    }
}
