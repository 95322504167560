import {
    ActionsActionTypes,
    IActionHistory,
    IActionsState,
} from '../../types/actionsTypes'
import { getActionFieldErrorKey } from '../../components/ActionsBar/utils/getActionFieldErrorKey'

const initialState: IActionsState = {
    systemActions: {},
    customActions: {},
    actionsParameters: {},
    actionsHistory: {},
    invalidActions: {},
    isLoading: false,
    error: '',
}

export const actionsReducer = (
    state: IActionsState = initialState,
    action: { type: string; payload: any }
): IActionsState => {
    const { type, payload } = action

    switch (type) {
        case ActionsActionTypes.GET_SYSTEM_ACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                systemActions: {
                    ...state.systemActions,
                    [payload.guid]: payload.systemActions,
                },
            }
        }
        case ActionsActionTypes.GET_CUSTOM_ACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customActions: {
                    ...state.customActions,
                    [payload.guid]: payload.customActions,
                },
            }
        }

        case ActionsActionTypes.GET_ACTIONS_HISTORY: {
            return {
                ...state,
                isLoading: false,
                actionsHistory: {
                    ...state.actionsHistory,
                    [payload.guid]: payload.productActionsHistory,
                },
            }
        }

        case ActionsActionTypes.ADD_ACTION_RESULT: {
            const { guid, actionResult } = payload
            return {
                ...state,
                isLoading: false,
                actionsHistory: {
                    ...state.actionsHistory,
                    [guid]: [actionResult, ...state.actionsHistory[guid]],
                },
            }
        }

        case ActionsActionTypes.UPDATE_ACTION: {
            const action: IActionHistory = payload.action
            const guid = action.product_id
            const actionId = action.action_id
            const actionItems = state.actionsHistory[guid]

            // iteration stops on first item since the record must be on top
            const oneAction = actionItems.find(
                (actionHistoryItem) => actionHistoryItem.action_id === actionId
            )

            if (oneAction) {
                return {
                    ...state,
                    isLoading: false,
                    actionsHistory: {
                        ...state.actionsHistory,
                        // must be a new array to update a React component
                        [guid]: actionItems.map((historyItem) =>
                            historyItem.action_id === actionId
                                ? action
                                : historyItem
                        ),
                    },
                }
            }

            return {
                ...state,
                isLoading: false,
                actionsHistory: {
                    ...state.actionsHistory,
                    [guid]: [action, ...state.actionsHistory[guid]],
                },
            }
        }

        case ActionsActionTypes.MODIFY_ACTION_PARAMETER: {
            const { path, data } = payload
            const [guid, actionName] = path
            const lastIndex = path.length - 1
            const isArray = Array.isArray(data)

            path.reduce((acc: any, cur: string, idx: number) => {
                if (
                    idx === lastIndex &&
                    data &&
                    Object.keys(data).includes(cur)
                ) {
                    acc[cur] = data[cur]
                }
                if (idx === lastIndex && data && isArray) {
                    acc[cur] = data
                }
                if (idx !== lastIndex && acc[cur] === undefined) {
                    acc[cur] = {}
                }
                return acc[cur]
            }, state.actionsParameters)

            return {
                ...state,
                actionsParameters: {
                    ...state.actionsParameters,
                    [guid]: {
                        ...state.actionsParameters[guid],
                        [actionName]: {
                            ...state.actionsParameters[guid]?.[actionName],
                        },
                    },
                },
            }
        }

        case ActionsActionTypes.RESET_ACTION_PARAMETERS: {
            const { guid, actionName } = payload

            return {
                ...state,
                actionsParameters: {
                    ...state.actionsParameters,
                    [guid]: {
                        ...state.actionsParameters[guid],
                        [actionName]: {},
                    },
                },
                /* remove action invalid fields */
                invalidActions: {
                    ...state.invalidActions,
                    [guid]: {
                        ...(state.invalidActions?.[guid] ?? {}),
                        [actionName]: {},
                    },
                },
            }
        }

        case ActionsActionTypes.ADD_CARD_ITEM_TO_ARRAY_END: {
            const { path, data } = payload
            const [guid, actionName] = path
            const lastIndex = path.length - 1

            path.reduce((acc: any, cur: string, idx: number) => {
                if (idx === lastIndex && data) {
                    acc[cur] = [...(acc[cur] ?? []), data]
                }
                if (idx !== lastIndex && acc[cur] === undefined) {
                    acc[cur] = {}
                }
                return acc[cur]
            }, state.actionsParameters)

            return {
                ...state,
                actionsParameters: {
                    ...state.actionsParameters,
                    [guid]: {
                        ...state.actionsParameters[guid],
                        [actionName]: {
                            ...state.actionsParameters[guid]?.[actionName],
                        },
                    },
                },
            }
        }

        case ActionsActionTypes.ADD_CARD_ITEM_TO_ARRAY_BEGINNING: {
            const { path, data } = payload
            const [guid, actionName] = path
            const lastIndex = path.length - 1

            path.reduce((acc: any, cur: string, idx: number) => {
                if (idx === lastIndex && data) {
                    acc[cur] = [data, ...(acc[cur] ?? [])]
                }
                if (idx !== lastIndex && acc[cur] === undefined) {
                    acc[cur] = {}
                }
                return acc[cur]
            }, state.actionsParameters)

            return {
                ...state,
                actionsParameters: {
                    ...state.actionsParameters,
                    [guid]: {
                        ...state.actionsParameters[guid],
                        [actionName]: {
                            ...state.actionsParameters[guid]?.[actionName],
                        },
                    },
                },
            }
        }

        case ActionsActionTypes.DELETE_ITEM_FROM_ARRAY: {
            const { path } = payload
            const [guid, actionName] = path
            const lastIndex = path.length - 1

            path.reduce((acc: any, cur: string, idx: number) => {
                if (idx === lastIndex) {
                    acc = structuredClone(acc.splice(cur, 1))
                }
                if (idx !== lastIndex && acc[cur] === undefined) {
                    acc[cur] = {}
                }
                return acc[cur]
            }, state.actionsParameters)

            return {
                ...state,
                actionsParameters: {
                    ...state.actionsParameters,
                    [guid]: {
                        ...state.actionsParameters[guid],
                        [actionName]: {
                            ...state.actionsParameters[guid]?.[actionName],
                        },
                    },
                },
            }
        }

        case ActionsActionTypes.SET_ACTION_INVALID: {
            const { path } = payload
            const [guid, actionName] = path
            const errorKey = getActionFieldErrorKey(path)

            return {
                ...state,
                invalidActions: {
                    ...state.invalidActions,
                    [guid]: {
                        ...(state.invalidActions?.[guid] ?? {}),
                        [actionName]: {
                            ...(state.invalidActions?.[guid]?.[actionName] ??
                                {}),
                            [errorKey]: true,
                        },
                    },
                },
            }
        }

        case ActionsActionTypes.SET_ACTION_VALID: {
            const { path } = payload
            const [guid, actionName] = path
            const errorKey = getActionFieldErrorKey(path)

            if (state.invalidActions[guid]?.[actionName]?.[errorKey]) {
                delete state.invalidActions[guid]?.[actionName]?.[errorKey]
            }

            return {
                ...state,
                invalidActions: {
                    ...state.invalidActions,
                    [guid]: {
                        ...(state.invalidActions?.[guid] ?? {}),
                        [actionName]: {
                            ...(state.invalidActions?.[guid]?.[actionName] ??
                                {}),
                        },
                    },
                },
            }
        }

        default:
            return state
    }
}
