import { DuplicateBtn } from '../../../buttons/DuplicateBtn/DuplicateBtn'
import styles from '../ScheduleItem.module.scss'
import AppButton from '../../../buttons/AppButton/AppButton'
import React, { useEffect, useRef, useState } from 'react'
import { ISchedule } from '../../../../types/schedule'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import {
    deleteSchedule,
    duplicateSchedule,
    toggleScheduleState,
} from '../../../../store/actions/schedulesActions'
import CheckboxInput from '../../../inputs/CheckboxInput'
import { useEffectAfterMount } from '../../../../hooks/useEffectAfterMount'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { selectProductJobScheduleByGuid } from '../../../../store/selectors/jobSchedulesSelectors'

interface ScheduleItemHeaderProps {
    guid: string
    schedule: ISchedule
    index: number
}

export const ScheduleItemHeader = (props: ScheduleItemHeaderProps) => {
    const { guid, schedule, index } = props
    const dispatch = useAppDispatch()

    const schedules = useTypedSelector(selectProductJobScheduleByGuid(guid))
    const stateRef = useRef(schedule.is_active)
    const [scheduleState, setScheduleState] = useState({
        isActive: schedule.is_active,
    })
    const disabled = schedule.is_active ? '' : 'opacity-75'
    const position = index + 1

    const handleDuplicate = () => {
        dispatch(duplicateSchedule({ guid, index }))
    }

    const handleDelete = () => {
        const updatedScheduleList = schedules.toSpliced(index, 1)
        dispatch(deleteSchedule({ guid, updatedScheduleList }))
    }

    // antipattern but necessary measure because of Checkbox component api
    useEffectAfterMount(() => {
        if (stateRef.current !== scheduleState.isActive) {
            stateRef.current = scheduleState.isActive
            dispatch(
                toggleScheduleState({
                    guid,
                    index,
                    isActive: scheduleState.isActive,
                })
            )
        }
    }, [scheduleState.isActive])

    // antipattern but necessary measure because of Checkbox component api
    const effectStaticCheck = stateRef.current !== schedule.is_active
    useEffect(() => {
        stateRef.current = schedule.is_active
        setScheduleState(prevState => ({ ...prevState, isActive: schedule.is_active }))
    }, [effectStaticCheck])

    return (
        <div
            className={`rounded-top d-flex justify-content-between block-bg-header-default px-4 py-1 ${disabled}`}
        >
            <span className="text-white align-self-center">{position}</span>
            <div className="d-flex gap-2">
                <CheckboxInput
                    state={scheduleState}
                    name={'isActive'}
                    label=""
                    className="block-switch block-header-3"
                    setState={setScheduleState}
                    checkboxType="switch-md"
                    inCard
                    isRuleActive={schedule.is_active}
                />
                <DuplicateBtn
                    className={styles.duplicateBtn}
                    onClick={handleDuplicate}
                />
                <AppButton
                    variant="delete"
                    onClick={handleDelete}
                    tooltip="tooltips.delete"
                    className={styles.deleteBtn}
                />
            </div>
        </div>
    )
}
