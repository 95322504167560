import { ISchedule } from '../../../types/schedule'
import { ScheduleItemHeader } from './components/ScheduleItemHeader'
import { ScheduleItemBody } from './components/SheduleItemBody'
import styles from './ScheduleItem.module.scss'
import { ScheduleSummary } from './components/ScheduleSummary'

interface ScheduleItemProps {
    guid: string
    index: number
    schedule: ISchedule
}

export const ScheduleItem = (props: ScheduleItemProps) => {
    const { guid, index, schedule } = props

    return (
        <div
            className={`pb-3 d-flex flex-column gap-4 rounded border ${styles.scheduleItem}`}
        >
            <ScheduleItemHeader
                guid={guid}
                schedule={schedule}
                index={index}
            />
            <ScheduleItemBody
                guid={guid}
                schedule={schedule}
                index={index}
            />
            <ScheduleSummary
                guid={guid}
                schedule={schedule}
            />
        </div>
    )
}
