import { ISchedulesReducer, ScheduleActionType } from '../../types/schedule'
import { produce } from 'immer'

const initialState: ISchedulesReducer = {
    jobSchedules: {
        isLoading: false,
        error: '',
        schedules: {},
    },
    schedulesHistory: {
        isLoading: false,
        error: '',
        productsStatusHistory: {},
    },
}

export const schedulesReducer = (
    state: ISchedulesReducer = initialState,
    action: { type: keyof typeof ScheduleActionType; payload: any }
): ISchedulesReducer => {
    const { type, payload } = action

    switch (type) {
        case ScheduleActionType.REFRESH_SCHEDULES_COMMON_STATES: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    isLoading: false,
                    error: '',
                },
                schedulesHistory: {
                    ...state.schedulesHistory,
                    isLoading: false,
                    error: '',
                },
            }
        }

        case ScheduleActionType.GET_JOB_SCHEDULE_REQUEST: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    isLoading: true,
                    error: '',
                },
            }
        }

        case ScheduleActionType.GET_JOB_SCHEDULE_SUCCESS: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    isLoading: false,
                    error: '',
                    schedules: {
                        ...state.jobSchedules.schedules,
                        [payload.guid]: payload.data,
                    },
                },
            }
        }

        case ScheduleActionType.GET_JOB_SCHEDULE_ERROR: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    isLoading: false,
                    error: 'errors.messages.onScheduleFetch',
                },
            }
        }

        case ScheduleActionType.ADD_JOB_SCHEDULE: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    schedules: {
                        ...state.jobSchedules.schedules,
                        [payload.guid]: payload.schedules,
                    },
                },
            }
        }

        case ScheduleActionType.CHANGE_JOB_SCHEDULE_DATE_FROM: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    schedules: {
                        ...state.jobSchedules.schedules,
                        [payload.guid]: state.jobSchedules.schedules[payload.guid].toSpliced(payload.index, 1, {
                            ...state.jobSchedules.schedules[payload.guid][payload.index],
                            period: {
                                ...state.jobSchedules.schedules[payload.guid][payload.index].period,
                                from: payload.dateFrom
                            }
                        }),
                    },
                },
            }
        }

        case ScheduleActionType.CHANGE_JOB_SCHEDULE_DATE_TO: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    schedules: {
                        ...state.jobSchedules.schedules,
                        [payload.guid]: state.jobSchedules.schedules[payload.guid].toSpliced(payload.index, 1, {
                            ...state.jobSchedules.schedules[payload.guid][payload.index],
                            period: {
                                ...state.jobSchedules.schedules[payload.guid][payload.index].period,
                                to: payload.dateTo
                            }
                        }),
                    },
                },
            }
        }

        case ScheduleActionType.CHANGE_JOB_SCHEDULE_DAYS: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    schedules: {
                        ...state.jobSchedules.schedules,
                        [payload.guid]: state.jobSchedules.schedules[payload.guid].toSpliced(payload.index, 1, {
                            ...state.jobSchedules.schedules[payload.guid][payload.index],
                            days_of_week: { ...payload.days }
                        }),
                    },
                },
            }
        }

        case ScheduleActionType.CHANGE_JOB_SCHEDULE_TIMES: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    schedules: {
                        ...state.jobSchedules.schedules,
                        [payload.guid]: state.jobSchedules.schedules[payload.guid].toSpliced(payload.index, 1, {
                            ...state.jobSchedules.schedules[payload.guid][payload.index],
                            start_time: payload.times
                        }),
                    },
                },
            }
        }

        case ScheduleActionType.TOGGLE_JOB_SCHEDULE: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    schedules: {
                        ...state.jobSchedules.schedules,
                        [payload.guid]: state.jobSchedules.schedules[payload.guid].toSpliced(payload.index, 1, {
                            ...state.jobSchedules.schedules[payload.guid][payload.index],
                            is_active: payload.isActive
                        }),
                    },
                },
            }
        }

        case ScheduleActionType.DELETE_JOB_SCHEDULE: {
            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    schedules: {
                        ...state.jobSchedules.schedules,
                        [payload.guid]: payload.updatedScheduleList,
                    },
                },
            }
        }

        case ScheduleActionType.DUPLICATE_JOB_SCHEDULE: {
            const productSchedules = state.jobSchedules.schedules[payload.guid]
            const duplicate = structuredClone(productSchedules[payload.index])
            const updatedScheduleList = productSchedules.toSpliced(
                payload.index,
                0,
                duplicate
            )

            return {
                ...state,
                jobSchedules: {
                    ...state.jobSchedules,
                    schedules: {
                        ...state.jobSchedules.schedules,
                        [payload.guid]: updatedScheduleList,
                    },
                },
            }
        }

        case ScheduleActionType.GET_SCHEDULE_HISTORY_REQUEST: {
            return produce(state, (draft) => {
                draft.schedulesHistory.isLoading = true
                draft.schedulesHistory.error = ''
            })
        }

        case ScheduleActionType.GET_SCHEDULE_HISTORY_SUCCESS: {
            return produce(state, (draft) => {
                draft.schedulesHistory.isLoading = false
                draft.schedulesHistory.error = ''

                const productsStatusHistory =
                    draft.schedulesHistory.productsStatusHistory
                productsStatusHistory[payload.guid] = payload.data
            })
        }

        case ScheduleActionType.GET_SCHEDULE_HISTORY_ERROR: {
            return produce(state, (draft) => {
                draft.schedulesHistory.isLoading = false
                draft.schedulesHistory.error =
                    'errors.messages.onScheduleHistoryFetch'
            })
        }

        default: {
            return state
        }
    }
}
