import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import React, { useEffect } from 'react'
import { IErrMsg } from './types'

interface TextInputProps {
    className?: string
    state: any
    touched?: any
    errors?: any
    errorText?: string
    name: string
    label?: string
    placeholder?: string
    isDisabled?: boolean
    tooltipText?: string
    isColumn?: boolean
    isLabelHidden?: boolean
    isRequired?: boolean
    isEmail?: boolean
    isFeedbackFixed?: boolean
    errMsg?: IErrMsg
    onEnter?: Function

    setState(state: any): any
    setTouched?(touched: any): any
}

export const TextInput = (props: TextInputProps) => {
    const {
        state,
        setState,
        touched,
        setTouched,
        errors,
        errMsg,
        name,
        className,
        placeholder,
        label,
        isDisabled,
        tooltipText,
        isColumn,
        isLabelHidden,
        isRequired,
        isEmail,
        isFeedbackFixed,
        onEnter,
    } = props
    const handleChange = (event: any): void => {
        setTouched && setTouched({ ...touched, [name]: true })
        setState({ ...state, [name]: event.target.value })
    }

    const handleBlur = (): void => {
        setTouched && setTouched({ ...touched, [name]: true })
    }

    const handleKeyDown = (evt: any) => {
        if (evt.key === 'Enter' && typeof onEnter === 'function') {
            onEnter()
        }
    }

    useEffect(() => {
        if (state?.[name] === null) {
            setState({ ...state, [name]: '' })
        }
    }, [state?.[name]]) // eslint-disable-line

    const isInvalid = touched && touched[name] && errors && errors[name]

    
    return (
        <Form.Group
            style={{ maxWidth: '600px' }}
            className={`${className} ${
                isInvalid && !isFeedbackFixed && 'mb-4'
            } d-flex flex-1 mt-1 mb-1 justify-content-between align-items-baseline`}
        >
            {label && !isLabelHidden ? (
                <Form.Label
                    style={{ maxWidth: isColumn ? '30%' : 'auto' }}
                    className="label-main mt-1 text-capital pe-2"
                >
                    <span className="label-main-wrapper p-relative">
                        {label}:{' '}
                        {isRequired && (
                            <span className="app-color-text label-required-mark">
                                *
                            </span>
                        )}
                    </span>
                </Form.Label>
            ) : null}

            <div
                style={{ width: '70%' }}
                className={`d-flex ${!isColumn ? 'flex-1' : ''} p-relative`}
            >
                <span className="d-flex flex-column w-100">
                    <Form.Control
                        as="textarea"
                        rows={5}
                        className={`input-main ${
                            tooltipText && 'input-padding'
                        }`}
                        type={!isEmail ? 'text' : 'email'}
                        placeholder={placeholder}
                        value={state?.[name] ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        disabled={!!isDisabled}
                        isInvalid={isInvalid}
                    />
                    <Form.Control.Feedback
                        className="input-feedback"
                        type="invalid"
                    >
                        <FormattedMessage
                            id={errMsg?.message || 'field-validation-error'}
                            values={errMsg?.values}
                            tagName="span"
                        />
                    </Form.Control.Feedback>
                </span>
                {tooltipText && (
                    <PromptBtn
                        className="mt-1 input-tooltip"
                        prompt={tooltipText ?? ''}
                    />
                )}
            </div>
        </Form.Group>
    )
}
