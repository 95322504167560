import {
    CoreSettingsActionType,
    ICoreSettingsReducer,
} from '../../types/coreSettings'

const initialState: ICoreSettingsReducer = {
    isLoading: false,
    error: '',
    productsCoreSettings: {},
}


export const coreSettingsReducer = (
    state: ICoreSettingsReducer = initialState,
    action: { type: keyof typeof CoreSettingsActionType, payload: any }
) => {
    const { type, payload } = action

    switch (type) {
        case CoreSettingsActionType.GET_CORE_SETTINGS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: '',
            }
        }

        case CoreSettingsActionType.GET_CORE_SETTINGS_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: 'errors.messages.onCoreSettingsFetch'
            }
        }

        case CoreSettingsActionType.GET_CORE_SETTINGS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: '',
                productsCoreSettings: {
                    ...state.productsCoreSettings,
                    [payload.guid]: payload.data
                }
            }
        }

        case CoreSettingsActionType.MODIFY_CORE_SETTINGS: {
            return {
                ...state,
                productsCoreSettings: {
                    ...state.productsCoreSettings,
                    [payload.guid]: payload.data
                }
            }
        }

        default: {
            return state
        }
    }
}