import { Card } from 'react-bootstrap'
import styles from '../index.module.scss'
import NoPictureImg from '../../../assets/images/noPic.jpg'
import { ReactEventHandler } from 'react'
import { HomePageItem } from '../HomePage'

interface NewsCardProps {
    news: HomePageItem
}

export const NewsCard = (props: NewsCardProps) => {
    const { news } = props

    const handleImageLoadError: ReactEventHandler<HTMLImageElement> = (e) => {
        // @ts-expect-error react cannot see the target is img tag
        e.target.src = NoPictureImg
    }

    return (
        <Card
            className={`${styles.contentBox} ${styles.newsCard} p-4 gap-3`}
            style={{ cursor: 'pointer', textDecoration: 'none' }}
            as={'a'}
            href={news.link}
            target="_blank"
            rel="noreferrer"
        >
            <img
                src={news.data}
                onError={handleImageLoadError}
                alt="news content image"
                style={{ borderRadius: '4px' }}
            />
            <Card.Body className="px-0">
                <Card.Title
                    as={'a'}
                    href={news.link}
                    target="_blank"
                    rel="noreferrer"
                    className={`${styles.title} app-color-main`}
                >
                    {news.title}
                </Card.Title>
                <Card.Text className="py-2">{news.description}</Card.Text>
                <Card.Link
                    className={`${styles.link} p-0`}
                    href={news.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {news.linkName}
                </Card.Link>
            </Card.Body>
        </Card>
    )
}