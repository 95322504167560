import { Dispatch } from 'redux'
import axios from 'axios'
import { apiVersion } from '../../versions'
import { getHeaders } from './http'
import { alertErrorMessage, throwSuccessMessage } from '../../utils/Errors'
import { DaysPicked, ISchedule, ScheduleActionType } from '../../types/schedule'
import { AppDispatch } from '../../types/store'
import { ProductStatus } from '../../entity/Product'

// ============ actions ============ //

export const refreshSchedulesCommonState = () => (dispatch: AppDispatch) => {
    dispatch({
        type: ScheduleActionType.REFRESH_SCHEDULES_COMMON_STATES,
    })
}

export const addSchedule =
    (payload: { guid: string; schedules: ISchedule[] }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ScheduleActionType.ADD_JOB_SCHEDULE,
            payload,
        })
    }

export const changeScheduleDateFrom =
    (payload: { guid: string; index: number; dateFrom: string }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ScheduleActionType.CHANGE_JOB_SCHEDULE_DATE_FROM,
            payload,
        })
    }

export const changeScheduleDateTo =
    (payload: { guid: string; index: number; dateTo: string | null }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ScheduleActionType.CHANGE_JOB_SCHEDULE_DATE_TO,
            payload,
        })
    }

export const changeScheduleDays =
    (payload: { guid: string; index: number; days: DaysPicked }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ScheduleActionType.CHANGE_JOB_SCHEDULE_DAYS,
            payload,
        })
    }

export const changeScheduleTimes =
    (payload: { guid: string; index: number; times: Array<string | null> }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ScheduleActionType.CHANGE_JOB_SCHEDULE_TIMES,
            payload,
        })
    }

export const deleteSchedule =
    (payload: { guid: string; updatedScheduleList: ISchedule[] }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ScheduleActionType.DELETE_JOB_SCHEDULE,
            payload,
        })
    }

export const toggleScheduleState =
    (payload: { guid: string; index: number; isActive: boolean }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ScheduleActionType.TOGGLE_JOB_SCHEDULE,
            payload,
        })
    }

export const duplicateSchedule =
    (payload: { guid: string; index: number }) => (dispatch: AppDispatch) => {
        dispatch({
            type: ScheduleActionType.DUPLICATE_JOB_SCHEDULE,
            payload,
        })
    }

// ============ action creators ============ //

export const fetchProductJobSchedule = (productId: string): any => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: ScheduleActionType.GET_JOB_SCHEDULE_REQUEST,
        })
        try {
            const { data } = await axios.get<Array<ISchedule>>(
                `/api/${apiVersion}/products/${productId}/core_settings/job_schedule`,
                {
                    headers: getHeaders(),
                }
            )
            dispatch({
                type: ScheduleActionType.GET_JOB_SCHEDULE_SUCCESS,
                payload: { guid: productId, data },
            })
        } catch (e: any) {
            dispatch({
                type: ScheduleActionType.GET_JOB_SCHEDULE_ERROR,
            })
            alertErrorMessage(e)
        }
    }
}

export const updateProductJobSchedule = (
    productId: string,
    schedule: Array<ISchedule>
): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            await axios.put(
                `/api/${apiVersion}/products/${productId}/core_settings/job_schedule`,
                schedule,
                {
                    headers: getHeaders(),
                }
            )
            dispatch(fetchProductJobSchedule(productId))

            throwSuccessMessage('Schedule is successfully updated')
        } catch (e: any) {
            alertErrorMessage(e)
            throw e
        }
    }
}

export const fetchProductSchedulesHistory = (
    productId: string,
    status: Uncapitalize<ProductStatus>,
    before?: string
): any => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: ScheduleActionType.GET_SCHEDULE_HISTORY_REQUEST,
        })
        try {
            const { data } = await axios.get<{
                has_more: boolean
                status_history: Array<ISchedule>
            }>(`/api/${apiVersion}/products/${productId}/status/history`, {
                headers: getHeaders(),
                params: {
                    status,
                    before: before || null,
                    limit: 300,
                },
            })

            if (!Array.isArray(data?.status_history)) {
                const error = new Error('Received status history in wrong format')
                console.error(error.message)
                return
            }

            dispatch({
                type: ScheduleActionType.GET_SCHEDULE_HISTORY_SUCCESS,
                payload: {
                    guid: productId,
                    data: {
                        hasMore: data.has_more,
                        historyRecords: data.status_history,
                    },
                },
            })
        } catch (e: any) {
            dispatch({
                type: ScheduleActionType.GET_SCHEDULE_HISTORY_ERROR,
            })
            alertErrorMessage(e)
        }
    }
}
