import { hashCode } from './math'

export const getIsPrevUser = (login: string) => {
    const prevItem = localStorage.getItem('prev')
    const prevUserHash = prevItem ? JSON.parse(prevItem) : null
    const userHash = hashCode(login)

    if (prevUserHash !== userHash) {
        // eslint-disable-next-line no-restricted-globals
        location.reload()
    }
}
