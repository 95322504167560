import React, { useEffect, useState } from 'react'
import { Card, Nav } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import style from '../Styles/index.module.scss'
import { IProduct } from '../../../types/product'
import { getLabel } from '../../../validators'
import ProductNameInput from '../../inputs/ProductNameInput'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { UnsavedChangesMark } from '../../UnsavedChangesMark'
import { User } from '../../../entity/User'
import { QuickAccessBtn } from '../../buttons/QuickAccessBtn/QuickAccessBtn'
import { getItemsList } from '../utils'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import {
    deleteProduct,
    renameProduct,
} from '../../../store/actions/products/productsActions'
import { deleteGroupEntry } from '../../../store/actions/products/groupsActions'
import { DraggableProvided } from 'react-beautiful-dnd'
import { BDropdown } from '../../overlays/BDropdown/BDropdown'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { ProductStatus } from '../../../entity/Product'

interface IProductCardHeader {
    product: IProduct
    groupId?: string
    provided?: DraggableProvided
}

const ProductCardHeader = ({
    product,
    groupId,
    provided,
}: IProductCardHeader) => {
    const authUser = new User()
    const dispatch = useDispatch()
    const { unsaved } = useTypedSelector((state) => state.configurations)
    const { status, guid, name, display_name: displayName } = product
    const [isProductNameEditing, setIsProductNameEditing] =
        useState<boolean>(false)
    const [productName, setProductName] = useState<string>(
        displayName || getLabel(name)
    )
    const isProductActive =
        status === ProductStatus.ACTIVE || status === ProductStatus.IDLE
    const isUnsaved: boolean = unsaved[guid]

    const handleOnEdit = (): void => {
        setIsProductNameEditing(true)
    }

    const handleOnDelete = (): void => {
        dispatch(
            showModal(ModalTypes.MAIN_MODAL, {
                body: `Delete ${productName}?`,
                onSubmit: () =>
                    dispatch(deleteProduct({ guid, name: productName })),
            })
        )
    }

    const handleOnRemoveFromGroup = (): void => {
        dispatch(deleteGroupEntry(groupId, guid))
    }

    const handleOnCancel = (): void => {
        setIsProductNameEditing(false)
        setProductName(displayName || getLabel(name))
    }

    const handleOnSave = (): void => {
        setIsProductNameEditing(false)
        dispatch(renameProduct(guid, productName))
    }

    const itemsList = getItemsList(
        groupId,
        handleOnEdit,
        handleOnRemoveFromGroup,
        handleOnDelete
    )

    useEffect(() => {
        if (displayName && displayName !== productName) {
            setProductName(displayName)
        }
    }, [isProductNameEditing, displayName]) // eslint-disable-line

    return (
        <Card.Header className={style.header}>
            <Card.Title
                style={{ minHeight: '50px' }}
                className="text-capitalize d-flex align-items-center word-break fw-500"
                {...provided?.dragHandleProps}
            >
                {!isProductNameEditing ? (
                    <>
                        <Nav.Link
                            style={{ color: '#3CB5DC' }}
                            className={`${
                                !isProductActive && 'disabled-link'
                            } text-decoration-none fw-500 w-90 p-0`}
                            disabled={!isProductActive}
                            as={Link}
                            to={`/${product.guid}`}
                        >
                            {productName}
                            {isUnsaved && (
                                <UnsavedChangesMark className="ms-1 app-color-default" />
                            )}
                        </Nav.Link>
                        <div className="d-flex flex-column align-items-center align-self-start ms-auto p-relative">
                            <QuickAccessBtn guid={product.guid} />
                            {authUser.isFullRightsManagerOrAbove() && (
                                <BDropdown
                                    dropdownItems={itemsList}
                                    tooltip="Product controls"
                                    dropdownToggle={
                                        <FontAwesomeIcon
                                            className="app-color-faded"
                                            icon={faEllipsisH}
                                        />
                                    }
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <ProductNameInput
                            name={productName}
                            setProductName={setProductName}
                            handleOnSave={handleOnSave}
                            handleOnCancel={handleOnCancel}
                            feedbackClassName="input-feedback"
                        />
                    </>
                )}
            </Card.Title>
        </Card.Header>
    )
}

export default ProductCardHeader
