import { apiVersion } from '../../versions'
import { Dispatch } from 'redux'
import { alertErrorMessage } from '../../utils/Errors'
import axios from 'axios'
import { getHeaders } from './http'
import {
    INotification,
    NotificationsActions,
    NotificationStatus,
} from '../../types/notifications'

const API = `/api/${apiVersion}/notifications`

// notifications are fetched in sidebar
export const fetchNotifications =
    (productIds?: string[]): any =>
    async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await axios.get<{
                notifications: INotification[]
                has_more: boolean
            }>(API, {
                headers: getHeaders(),
                params: {
                    product_ids: productIds,
                },
                paramsSerializer: {
                    indexes: null,
                },
            })

            if (typeof data?.has_more !== 'boolean' || !Array.isArray(data?.notifications) ) {
                const error = new Error('Received notifications in wrong format')
                console.error(error.message)
                return
            }

            dispatch({
                type: NotificationsActions.FETCH_NOTIFICATIONS_SUCCESS,
                payload: { hasMore: data.has_more, notifications: data.notifications },
            })
        } catch (e) {
            alertErrorMessage(e)
        }
    }

export const fetchNotificationsOnScroll =
    (lastNotificationId: string | undefined = '', productIds: string[]): any =>
    async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await axios.get<{
                notifications: INotification[]
                has_more: boolean
            }>(API, {
                headers: getHeaders(),
                params: {
                    before: lastNotificationId,
                    product_ids: productIds,
                },
                paramsSerializer: {
                    indexes: null,
                },
            })

            if (typeof data?.has_more !== 'boolean' || !Array.isArray(data?.notifications) ) {
                const error = new Error('Received notifications in wrong format')
                console.error(error.message)
                return
            }

            dispatch({
                type: NotificationsActions.FETCH_NOTIFICATIONS_ONSCROLL,
                payload: { hasMore: data.has_more, notifications: data.notifications },
            })
        } catch (e) {
            alertErrorMessage(e)
        }
    }

export const markFilteredNotificationsRead =
    (productIDs: string[]): any =>
    async (dispatch: Dispatch<any>) => {
        try {
            await axios.put(`${API}/status`, {
                product_ids: productIDs,
                status: NotificationStatus.READ
            }, {
                headers: getHeaders(),
            })
            dispatch({
                type: NotificationsActions.MARK_NOTIFICATIONS_READ,
            })
        } catch (e) {
            alertErrorMessage(e)
        }
    }

export const markAllNotificationsRead =
    (): any =>
        async (dispatch: Dispatch<any>) => {
            try {
                await axios.put(`${API}/status`,
                    {product_ids: null, status: NotificationStatus.READ }, {
                    headers: getHeaders(),
                })
                dispatch({
                    type: NotificationsActions.MARK_NOTIFICATIONS_READ,
                })
            } catch (e) {
                alertErrorMessage(e)
            }
        }

export const markNotification =
    (notificationId: string, status: NotificationStatus): any =>
    async (dispatch: Dispatch<any>) => {
        try {
            await axios.put(
                `${API}/${notificationId}/status`,
                { status },
                {
                    headers: getHeaders(),
                }
            )
            dispatch({
                type: NotificationsActions.MARK_NOTIFICATION,
                payload: { notificationId, status },
            })
        } catch (e) {
            alertErrorMessage(e)
        }
    }