import { memo, useCallback, useState } from 'react'
import { ActionStartReset } from '../ActionStartReset/ActionStartReset'
import { ActionParameters } from '../ActionParameters/ActionParameters'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectOneCustomAction } from '../selectors/actionsSelectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'

interface ActionUiProps {
    guid: string
    actionName: string
}

export const ActionUi = memo(function ActionUi(props: ActionUiProps) {
    const { guid, actionName } = props

    const [remountKey, setRemountKey] = useState(Math.random)
    const customAction =
        useTypedSelector(selectOneCustomAction(guid, actionName)) ?? {}
    const hasParameters = customAction?.request_schema?.children
        ? !!Object.keys(customAction.request_schema.children).length
        : !!Object.keys(customAction?.request_schema).length

    const resetActionParametersComponent = useCallback(
        (newKey: number) => setRemountKey(newKey),
        []
    )

    return (
        <div>
            {hasParameters ? (
                <ActionParameters
                    key={`${guid}-${actionName}-${remountKey}`}
                    guid={guid}
                    actionName={actionName}
                />
            ) : (
                <div>
                    <FontAwesomeIcon
                        color={'#4B66874D'}
                        icon={faCircleCheck}
                    />{' '}
                    &nbsp; This action doesn't require parameters to run
                </div>
            )}
            <ActionStartReset
                guid={guid}
                actionName={actionName}
                hasParameters={hasParameters}
                onReset={resetActionParametersComponent}
            />
        </div>
    )
})
