import { User, UserPermissions, UserRoles } from '../entity/User'

export const createUserPermissions = (role: UserRoles) => {
    switch (role) {
        case UserRoles.OWNER:
            return [
                UserPermissions.USER_ADMINISTRATION,
                UserPermissions.USER_MANAGEMENT,
                UserPermissions.GROUP_MANAGEMENT,
                UserPermissions.CONFIGURATION_ADMINISTRATION,
                UserPermissions.CONFIGURATION_MANAGEMENT,
                UserPermissions.CONFIGURATION_READ,
            ]

        case UserRoles.ADMINISTRATOR:
            return [
                UserPermissions.USER_MANAGEMENT,
                UserPermissions.GROUP_MANAGEMENT,
                UserPermissions.CONFIGURATION_ADMINISTRATION,
                UserPermissions.CONFIGURATION_MANAGEMENT,
                UserPermissions.CONFIGURATION_READ,
            ]

        case UserRoles.FULL_RIGHTS_MANAGER:
            return [
                UserPermissions.CONFIGURATION_ADMINISTRATION,
                UserPermissions.CONFIGURATION_MANAGEMENT,
                UserPermissions.CONFIGURATION_READ,
            ]

        case UserRoles.WL_MANAGER:
            return [
                UserPermissions.CONFIGURATION_MANAGEMENT,
                UserPermissions.CONFIGURATION_READ,
            ]

        default:
            return [UserPermissions.CONFIGURATION_READ]
    }
}

export const createUserRoles = (authUser: User, readOnly?: boolean) => {
    if (readOnly) {
        return [
            UserRoles.OWNER,
            UserRoles.ADMINISTRATOR,
            UserRoles.FULL_RIGHTS_MANAGER,
            UserRoles.WL_MANAGER,
            UserRoles.VIEWER,
        ]
    }
    if (authUser.isOwner()) {
        return [
            UserRoles.OWNER,
            UserRoles.ADMINISTRATOR,
            UserRoles.FULL_RIGHTS_MANAGER,
            UserRoles.WL_MANAGER,
            UserRoles.VIEWER,
        ]
    }

    if (authUser.isAdministrator()) {
        return [
            UserRoles.ADMINISTRATOR,
            UserRoles.FULL_RIGHTS_MANAGER,
            UserRoles.WL_MANAGER,
            UserRoles.VIEWER,
        ]
    }

    return [
        UserRoles.FULL_RIGHTS_MANAGER,
        UserRoles.WL_MANAGER,
        UserRoles.VIEWER,
    ]
}
