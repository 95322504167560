import { ProductStatus } from '../entity/Product'
import { ProductActionType } from '../types/product'
import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'
import { logOnSignalRMethodTrigger } from './lib/logOnSignalRMethodTrigger'
import { SignalRMethods } from './lib/SignalRMethods'

export const receiveProductStatus = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    const productStatuses = Object.values(ProductStatus)

    connection.on(SignalRMethods.PRODUCT_STATUS, (productId, status) => {
        logOnSignalRMethodTrigger(
            SignalRMethods.PRODUCT_STATUS,
            productId,
            status
        )
        if (productId && productStatuses.includes(status)) {
            dispatch({
                type: ProductActionType.CHANGE_PRODUCT_STATUS,
                payload: { productId, status },
            })
        }
    })
}
