import { HomePageItem } from '../HomePage'
import styles from '../index.module.scss'
import { ContentUnavailable } from '../ContentUnavailable'
import { FactsSkeleton } from './FactsSkeleton'
import { Carousel } from 'react-bootstrap'
import { FactCard } from './FactCard'

const TFB_PRODUCTS_URL = 'https://t4b.com/the-tfb-ecosystem/'

interface FactsProps {
    isLoading: boolean
    isError: boolean
    facts: Array<HomePageItem[]>
}

export const Facts = (props: FactsProps) => {
    const { isError, isLoading, facts } = props

    return (
        <div className={`${styles.contentBox} p-5 flex-1 flex-shrink-0`}>
            {isError ? (
                <ContentUnavailable
                    link={TFB_PRODUCTS_URL}
                    linkDisplayName={'Open product catalog'}
                    textMessage={
                        'Currently, product information unavailable. Please explore products on our website'
                    }
                />
            ) : isLoading ? (
                <FactsSkeleton />
            ) : (
                <Carousel controls={false}>
                    {!!facts?.length &&
                        facts.map((factItems, idx) => (
                            <Carousel.Item key={idx.toString()}>
                                <div className={`d-flex  gap-4 w-100`}>
                                    {!!factItems.length &&
                                        factItems.map((fact) => (
                                            <FactCard
                                                key={fact?.id}
                                                fact={fact}
                                            />
                                        ))}
                                </div>
                            </Carousel.Item>
                        ))}
                </Carousel>
            )}
        </div>
    )
}