export const coreSettingsErrors = {
    restartAttempts(state: any) {
        if (state?.restart_attempts < 0) {
            return {
                message: 'errors.validation.greaterOrEqual',
                values: { greaterOrEqual: 0 },
            }
        }
    },
    restartDelaySec(state: any) {
        if (state?.restart_delay_sec < 0) {
            return {
                message: 'errors.validation.greaterOrEqual',
                values: { greaterOrEqual: 0 },
            }
        }
    },
}
