import React from 'react'
import { ButtonWithTooltip } from '../ButtonWithTooltip'
import styles from './DuplicateBtn.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone } from '@fortawesome/free-solid-svg-icons/faClone'

interface DuplicateBtnProps {
    onClick: () => void
    className?: string
}

export const DuplicateBtn = (props: DuplicateBtnProps) => {
    const { onClick, className } = props

    return (
        <ButtonWithTooltip tooltip={'Duplicate'}>
            <button
                onClick={onClick}
                className={`${styles.duplicateBtn} ${className}`}
            >
                <FontAwesomeIcon icon={faClone} />
            </button>
        </ButtonWithTooltip>
    )
}
