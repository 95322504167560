export enum TableViewActionType {
    TABLE_VIEW = 'TABLE_VIEW',
    IS_LOADING = 'IS_LOADING',
}

const initialState = {
    isTableView: localStorage.getItem('tableView') === 'true' || false,
    isLoading: false
}

export const tableViewReducer = (
    state = initialState,
    action: { type: TableViewActionType, payload: boolean }
) => {

    switch (action.type) {
        case TableViewActionType.TABLE_VIEW: {
            localStorage.setItem('tableView', String(action.payload));
            return { ...state, isTableView: action.payload }
        }

        case TableViewActionType.IS_LOADING: {
            return { ...state, isLoading: action.payload }
        }

        default: {
            return state
        }
    }
}
