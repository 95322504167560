import { HomePageItem } from '../../components/HomePage/HomePage'

const PROD_HMS_URL = 'https://hms.t4b.com/'
const DEV_HMS_URL = 'https://homedev.tfbdev.com/'

const getResourceAddress = (originAddress: string) =>
    originAddress.includes('test') || originAddress.includes('dev')
        ? DEV_HMS_URL
        : PROD_HMS_URL

export const getFacts = async (
    windowOrigin: string
): Promise<HomePageItem[]> => {
    const resourceAddress = getResourceAddress(windowOrigin)

    const result = await fetch(
        `${resourceAddress}api/v1/facts/random?count=8`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    return await result.json()
}

export const getNews = async (
    windowOrigin: string
): Promise<HomePageItem[]> => {
    const resourceAddress = getResourceAddress(windowOrigin)

    const result = await fetch(
        `${resourceAddress}api/v1/news?` +
            new URLSearchParams({
                page: '1',
                limit: '12',
            }).toString(),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    const news = await result.json()

    return news.items
}