import { CoreSettingsBackend, ICoreSettings } from '../../../types/coreSettings'

export const idleAndStandByMap = {
    idle: 'on_standby',
    on_standby: 'idle',
} as const

export const idleAndStandByMapper = (data: ICoreSettings | CoreSettingsBackend) => {
    if (!data || typeof data !== 'object') {
        return data
    }

    let mappedData = { ...data }

    if (data.product_status_on_start === 'idle' || data.product_status_on_start === 'on_standby') {
        mappedData.product_status_on_start = idleAndStandByMap[data.product_status_on_start]
    }

    return mappedData
}
