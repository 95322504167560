import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import styles from './LogsPage.module.scss'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IProduct } from '../../types/product'
import { convertToSentenceCase } from '../../utils/convertToSentenceCase'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProductLiveLogs } from '../../store/actions/logsActions'
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { fuzzyFilter } from '../../utils/fuzzyFilter'
import { ILiveLog, LEVELS } from '../../types/logs'
import { NoData } from '../NoData'

const userLocale = navigator.language || 'en-US'
const emptyArr: any = []

export const LogsPage = () => {
    const dispatch = useDispatch()
    const { productId } = useParams<{ productId: string }>()
    const { products } = useTypedSelector((store) => store.products)
    const curProduct = products.find(
        (product: IProduct) => product.guid === productId
    )
    const { productsLiveLogs } = useTypedSelector((store) => store.logs)
    const productLiveLogs = productsLiveLogs[productId] ?? emptyArr

    const columns = useMemo<ColumnDef<unknown>[]>(
        () => [
            {
                accessorKey: 'log_time',
                cell: (info) => {
                    if (info.getValue()) {
                        return new Date(
                            info.getValue() as string
                        ).toLocaleString(userLocale)
                    }
                },
                header: () => 'TIME',
            },
            {
                accessorKey: 'level',
                cell: (info) => LEVELS[info.getValue() as keyof typeof LEVELS],
                header: () => 'LEVEL',
            },
            {
                accessorKey: 'tag',
                cell: (info) => info.getValue(),
                header: () => 'TAG',
            },
            {
                accessorKey: 'message_template',
                cell: (info) => info.getValue(),
                header: () => 'MESSAGE',
            },
        ],
        []
    )

    const table = useReactTable({
        data: productLiveLogs,
        columns: columns,
        filterFns: { fuzzy: fuzzyFilter },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    })

    useEffect(() => {
        const abortController = new AbortController()
        dispatch(fetchProductLiveLogs(productId, abortController.signal))

        return () => abortController.abort()
    }, [dispatch, productId])

    return (
        <Modal
            show
            fullscreen
            keyboard={false}
            className="p-0 m-0"
        >
            <Modal.Body className={`app-bg-primary ${styles.container}`}>
                <h4>Live logs</h4>
                Product:{' '}
                <span className={styles.pName}>
                    {curProduct ? convertToSentenceCase(curProduct.name) : ''}
                </span>
                {!!productLiveLogs.length ? (
                    <table className={styles.table}>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                className={styles.tableHead}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div>
                                                        {flexRender(
                                                            header.column
                                                                .columnDef
                                                                .header,
                                                            header.getContext()
                                                        )}
                                                    </div>
                                                )}
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => {
                                const originalLiveLogObj = row.original as ILiveLog
                                const lvl = originalLiveLogObj.level
                                return (
                                    <tr
                                        key={row.id}
                                        className={`${styles.tableRow} ${styles[lvl]}`}
                                    >
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td
                                                    key={cell.id}
                                                    className={styles.tableCell}
                                                >
                                                    <div
                                                        className={
                                                            styles.cellContent
                                                        }
                                                    >
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <NoData style={{ height: '300px' }} />
                )}
            </Modal.Body>
        </Modal>
    )
}
