import { HomePageItem } from '../components/HomePage/HomePage'

export const groupHomePageItems = (
    items: HomePageItem[],
    groupSize: number = 8
) => {
    const groupedArray = []

    for (let i = 0; i < items.length; i += groupSize) {
        groupedArray.push(items.slice(i, i + groupSize))
    }

    return groupedArray
}