import { useTypedSelector } from '../../hooks/useTypedSelector'
import { uiVersion } from '../../versions'
import styles from './index.module.scss'
import { selectProducts } from '../../store/selectors/productsSelectors'
import { ProductStatus } from '../../entity/Product'
import { useEffect } from 'react'
import { fetchInfo } from '../../store/actions/infoActions'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import GreenBarsImg from '../../assets/images/greenBars.png'

export const MainInfo = () => {
    const dispatch = useAppDispatch()
    const { info } = useTypedSelector((state: any) => state.info)
    const { app_version: appVersion } = info
    const products = useTypedSelector(selectProducts)

    const activeProductsCount = products.filter(
        (product) => product.status === ProductStatus.ACTIVE
    ).length

    const inactiveProductsCount = products.filter(
        (product) => product.status === ProductStatus.INACTIVE
    ).length

    const idleProductsCount = products.filter(
        (product) => product.status === ProductStatus.IDLE
    ).length

    useEffect(() => {
        dispatch(fetchInfo())
    }, [dispatch])

    return (
        <div
            className={`${styles.contentBox} ${styles.mainInfo} d-flex flex-1`}
        >
            <div
                className={`p-5 flex-1 flex-column`}
                style={{
                    backgroundImage: 'url(' + GreenBarsImg + ')',
                    backgroundSize: '400px 500px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right',
                }}
            >
                <div className={`${styles.mainTitle} mb-3`}>
                    Welcome to Toolbox
                </div>
                <div>Toolbox ui v{uiVersion}</div>
                <div className="mb-3">Toolbox app v{appVersion}</div>
                <div>
                    Active
                    <span className="p-active" /> : {activeProductsCount}
                </div>
                <div>
                    Inactive
                    <span className="p-inactive" /> : {inactiveProductsCount}
                </div>
                <div>
                    On standby
                    <span className="p-idle" /> : {idleProductsCount}
                </div>
            </div>
        </div>
    )
}