import { ConfigurationsActionType } from '../../../types/configurations'

interface IAddActions {
    [key: string]: Function
}

const addActions: IAddActions = {
  [ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_TOP]: (
    state: any,
    name: string,
    data: any
  ) => (state[name] = [data, ...state[name]]),
  [ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_BOTTOM]: (
    state: any,
    name: string,
    data: any
  ) => (state[name] = [...state[name], data]),
  [ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_TO_END]: (
    state: any,
    name: string,
    data: any
  ) => (state[name] = [...state[name], data]),
  [ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_TO_BEGINNING]: (
    state: any,
    name: string,
    data: any
  ) => (state[name] = [ data, ...state[name]]),
  [ConfigurationsActionType.BLOCK_ARRAY_CARD_DUPLICATE]: (
    state: any,
    name: string,
    data: any
  ) => (state[name] = state[name].toSpliced(data.index, 0, data.item)),
  // Note: this method adds 100 cards and only for testing
  [ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_100]: (
    state: any,
    name: string,
    data: any
  ) => (state[name] = [ ...state[name], ...data]),
}

export const blockArrayActions = {
  DND(state: any, path: string[], item: any, data: any, length = 1) {
    let currentPathLength: number = length
    const currentState: any =
      currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (
      currentPathLength === path.length &&
      typeof state[name] !== 'undefined'
    ) {
      return (state[name] = item)
    }

    if (currentPathLength < path.length) {
      this.DND(
        currentState?.[path[length - 1]],
        path,
        item,
        data,
        (currentPathLength += 1)
      )
    }
  },
  tableModify(state: any, path: string[], index: any, data: any, length = 1) {
    let currentPathLength: number = length
    const currentState: any =
      currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (
      currentPathLength === path.length &&
      typeof state[name] !== 'undefined'
    ) {
      return (state[name][index] = data)
    }

    if (currentPathLength < path.length) {
      this.tableModify(
        currentState?.[path[length - 1]],
        path,
        index,
        data,
        (currentPathLength += 1)
      )
    }
  },
  addItem(
    state: any,
    path: string[],
    data: any,
    length = 1,
    actionType: string = ''
  ) {
    let currentPathLength: number = length
    const currentState: any =
      currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (
      currentPathLength === path.length &&
      typeof state[name] !== 'undefined'
    ) {
      return addActions?.[actionType](state, name, data)
    }

    if (currentPathLength < path.length) {
      this.addItem(
        currentState?.[path[length - 1]],
        path,
        data,
        (currentPathLength += 1),
        actionType
      )
    }
  },
  deleteItem(state: any, path: string[], index: any, data?: any, length = 1) {
    let currentPathLength: number = length
    const currentState: any =
      currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (
      currentPathLength === path.length &&
      typeof state[name] !== 'undefined'
    ) {
      return (state[name] = state[name].filter(
        (el: any, i: number) => i !== index
      ))
    }

    if (currentPathLength < path.length) {
      this.deleteItem(
        currentState?.[path[length - 1]],
        path,
        index,
        data,
        (currentPathLength += 1)
      )
    }
  },
  deleteItemCard(state: any, path: string[], data?: any, length = 1) {
    let currentPathLength: number = length
    const currentState: any =
      currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (
      currentPathLength === path.length &&
      typeof state[name] !== 'undefined'
    ) {
      return (state[name] = data)
    }

    if (currentPathLength < path.length) {
      this.deleteItemCard(
        currentState?.[path[length - 1]],
        path,
        data,
        (currentPathLength += 1)
      )
    }
  },
  refreshRules(state: any, path: string[]) {
    path.reduce((acc: typeof state, cur, idx) => {
      if (idx === path.length - 1) {
        return (acc[cur] = [...acc[cur]])
      }
      return acc[cur]
    }, state?.productsConfigurations ?? {})
  },
  updateRulesPartial(state: any, path: string[], updatedRules: any[]) {
    path.reduce((acc: any, cur: string, idx: number) => {
      if (idx === path.length - 1) {

        const rules = acc[cur]
        const result = structuredClone(rules)

        for (const updatedRule of updatedRules) {
          for (let i = 0, len = rules.length; i < len; i++) {
            if (rules[i].element_id === updatedRule.element_id) {
              result[i] = updatedRule
            }
          }
        }

        // replace rules in interim config
        path.reduce((accumulator: any, current: string, index: number) => {
          if (index === path.length - 1) {
            return accumulator[current] = structuredClone(result)
          }
          return accumulator[current]
        }, state?.productsConfigurationsInterim ?? {})

        // replace rules in current config
        acc[cur] = result
      }

      return acc[cur]
    }, state?.productsConfigurations ?? {})
  },

  replaceTable(state: any, path: string[], data: any[]) {
    path.reduce((acc: typeof state, cur, idx) => {
      if (idx === path.length - 1) {
        return (acc[cur] = data)
      }
      return acc[cur]
    }, state?.productsConfigurations ?? {})
  },
  importDataFromCsv(state: any, path: string[], data: any[]) {
    path.reduce((acc: typeof state, cur, idx) => {
      if (idx === path.length - 1) {
        return acc[cur] = data
      }
      return acc[cur]
    }, state?.productsConfigurations ?? {})
  },
  modifyTable(state: any, path: string[], data: any, elementId: string) {
    path.reduce((acc: typeof state, cur, idx) => {
      if (idx === path.length - 1) {
        return Array.isArray(acc[cur]) ? acc[cur].map((item: any) => {
          if (item.element_id === elementId) {
            return data
          }
          return item
        }) : []
      }
      return acc[cur]
    }, state?.productsConfigurations ?? {})
  }
}
