import {
    faBoxesStacked,
    faUserGear,
    faUserGroup,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Account } from '../components/admin/Account/Account'
import { Controller } from '../components/admin/AccountController/Controller'
import NotEnoughPrivilege from '../components/NotEnoughPrivilege'
import Summary from '../components/Summary/Summary'
import { UserPermissions } from '../entity/User'
import { LogsPage } from '../components/LogsPage/LogsPage'
import { HomePage } from '../components/HomePage/HomePage'
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse'

export interface IRoute {
    name: string
    path: string
    exact: boolean
    permission: string
    component?: JSX.Element
    icon?: JSX.Element
    dropdown?: any
}

export enum RoutesPaths {
    HOME = '/home',
    SUMMARY = '/summary/*',
    USERS = '/users',
    ACCOUNT = '/account',
    PRODUCTS = '/summary/products',
    LOGS = '/:productId/logs',
    QUICK_ACCESS = '/summary/quick-access',
    NOT_ENOUGH_PRIVILEGE = '/not-enough-privilege',
    REFRESH_TOKEN = '/api/oauth/refresh_token',
}

export const routes: IRoute[] = [
    {
        name: 'Home',
        path: RoutesPaths.HOME,
        component: <HomePage />,
        exact: true,
        permission: UserPermissions.CONFIGURATION_MANAGEMENT,
        icon: (
            <FontAwesomeIcon
                icon={faHouse}
                size="2x"
            />
        ),
    },
    {
        name: 'Products',
        path: RoutesPaths.SUMMARY,
        component: <Summary />,
        exact: true,
        permission: UserPermissions.CONFIGURATION_MANAGEMENT,
        icon: (
            <FontAwesomeIcon
                icon={faBoxesStacked}
                size="2x"
            />
        ),
        dropdown: true,
    },
    {
        name: 'Users',
        path: RoutesPaths.USERS,
        component: <Controller />,
        exact: true,
        permission: UserPermissions.USER_MANAGEMENT,
        icon: (
            <FontAwesomeIcon
                icon={faUserGroup}
                size="2x"
            />
        ),
    },
    {
        name: 'Account',
        path: RoutesPaths.ACCOUNT,
        component: <Account />,
        exact: true,
        permission: UserPermissions.CONFIGURATION_MANAGEMENT,
        icon: (
            <FontAwesomeIcon
                icon={faUserGear}
                size="2x"
            />
        ),
    },
    {
        name: 'Logs',
        path: RoutesPaths.LOGS,
        component: <LogsPage />,
        exact: true,
        permission: '_',
    },
    {
        name: 'NotEnoughPrivilege',
        path: RoutesPaths.NOT_ENOUGH_PRIVILEGE,
        component: <NotEnoughPrivilege />,
        exact: true,
        permission: '_',
    },
]
