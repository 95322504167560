import React from 'react'
import { User } from '../entity/User'
import { FormattedMessage } from 'react-intl'

export const UserRoleDescription = ({ authUser }: { authUser: User }) => (
    <div>
        {authUser.isOwner() && (
            <div>
                <p>
                    <span className="fw-bold">
                        <FormattedMessage id="user.userRoles.owner" />:{' '}
                    </span>
                    <FormattedMessage id="user.userRoles.ownerDescription" />
                </p>
            </div>
        )}
        <div>
            <p>
                <span className="fw-bold">
                    <FormattedMessage id="user.userRoles.administrator" />:{' '}
                </span>
                <FormattedMessage id="user.userRoles.administratorDescription" />
            </p>
        </div>
        <div>
            <p>
                <span className="fw-bold">
                    <FormattedMessage id="user.userRoles.fullRightsManager" />:{' '}
                </span>
                <FormattedMessage id="user.userRoles.fullRightsManagerDescription" />
            </p>
        </div>
        <div>
            <p>
                <span className="fw-bold">
                    <FormattedMessage id="user.userRoles.wlManager" />:{' '}
                </span>
                <FormattedMessage id="user.userRoles.wlManagerDescription" />
            </p>
        </div>
        <div>
            <p>
                <span className="fw-bold">
                    <FormattedMessage id="user.userRoles.viewer" />:{' '}
                </span>
                <FormattedMessage id="user.userRoles.viewerDescription" />
            </p>
        </div>
    </div>
)
