import {
    ProductsUtcOffsetActions,
    ProductsUtcOffsetReducer,
} from '../../types/productsUtcOffset'

const initialState = {}

export const productsUtcOffsetReducer = (
    state: ProductsUtcOffsetReducer = initialState,
    action: { type: keyof typeof ProductsUtcOffsetActions; payload: any }
): ProductsUtcOffsetReducer => {
    const { type, payload } = action

    switch (type) {
        case ProductsUtcOffsetActions.SET_PRODUCT_UTC_OFFSET: {
            return {
                ...state,
                [payload.guid]: payload.data,
            }
        }

        default: {
            return state
        }
    }
}