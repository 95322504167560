import { NewsCard } from './NewsCard'
import { HomePageItem } from '../HomePage'
import styles from '../index.module.scss'

interface NewsRowProps {
    newsItems: HomePageItem[]
}
export const NewsRow = (props: NewsRowProps) => {
    const { newsItems } = props
    return (
        <div className={`${styles.gridContainer}`}>
            {newsItems.map((newsItem) => (
                <NewsCard
                    key={newsItem.id}
                    news={newsItem}
                />
            ))}
        </div>
    )
}