import { StoreSchema } from '../../types/store'
import { createSelector } from 'reselect'

export const selectCoreSettingsState = (store: StoreSchema) =>
    store.coreSettings

export const selectCoreSettings = (store: StoreSchema) =>
    store.coreSettings.productsCoreSettings

export const selectProductCoreSettings = (guid: string) =>
    createSelector(
        [selectCoreSettings],
        (allProductsCoreSettings) => allProductsCoreSettings[guid]
    )
