import {
    faArrowRightFromBracket,
    faEdit,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { User } from '../../../entity/User'
import { AppDropdownItem } from '../../overlays/AppDropdown/AppDropdown'

export const getItemsList = (
    groupId: string | undefined,
    handleOnEdit: () => void,
    handleOnRemove: () => void,
    handleOnDelete: (guid: string, productName: string) => void
): AppDropdownItem[] => {
    const user = new User()

    const deleteProduct: AppDropdownItem = {
        name: 'productCard.deleteProductBtn',
        icon: faTrashAlt,
        action: handleOnDelete,
        className: 'app-color-red',
    }

    const editProduct: AppDropdownItem = {
        name: 'productCard.editNameBtn',
        icon: faEdit,
        action: handleOnEdit,
    }

    const removeFromGroup: AppDropdownItem = {
        name: 'productCard.removeFromGroupBtn',
        icon: faArrowRightFromBracket,
        action: handleOnRemove,
    }

    const buttons = []

    if (user.isAdministratorOrOwner()) {
        if (groupId) {
            buttons.push(removeFromGroup)
        }
    }

    if (user.isFullRightsManagerOrAbove()) {
        buttons.push(editProduct)
        buttons.push(deleteProduct)
    }

    return buttons
}
