import * as yup from 'yup'
import { IUserBackend, User, UserPermissions, UserRoles } from './User'

export class NewUser implements IUserBackend {
    login: string
    password: string
    permissions: UserPermissions[]
    visibility: {
        products: string[]
        groups: string[]
        all_products: boolean
    }
    role: UserRoles

    schema = {
        login: yup.string().required(),
        password: yup.string().min(5).required(),
    }

    constructor() {
        this.login = ''
        this.password = ''
        this.permissions = [UserPermissions.CONFIGURATION_READ]
        this.visibility = {
            products: [],
            groups: [],
            all_products: false,
        }
        this.role = User.getRole(this)
    }
}