import { ProductStatus } from '../entity/Product'

export const ScheduleActionType = {
    REFRESH_SCHEDULES_COMMON_STATES: 'REFRESH_SCHEDULES_COMMON_STATES',
    GET_JOB_SCHEDULE_REQUEST: 'GET_JOB_SCHEDULE_REQUEST',
    GET_JOB_SCHEDULE_SUCCESS: 'GET_JOB_SCHEDULE_SUCCESS',
    GET_JOB_SCHEDULE_ERROR: 'GET_JOB_SCHEDULE_ERROR',
    ADD_JOB_SCHEDULE: 'ADD_JOB_SCHEDULE',
    DELETE_JOB_SCHEDULE: 'DELETE_JOB_SCHEDULE',
    TOGGLE_JOB_SCHEDULE: 'TOGGLE_JOB_SCHEDULE',
    DUPLICATE_JOB_SCHEDULE: 'DUPLICATE_JOB_SCHEDULE',
    CHANGE_JOB_SCHEDULE_DATE_FROM: 'CHANGE_JOB_SCHEDULE_DATE_FROM',
    CHANGE_JOB_SCHEDULE_DATE_TO: 'CHANGE_JOB_SCHEDULE_DATE_TO',
    CHANGE_JOB_SCHEDULE_DAYS: 'CHANGE_JOB_SCHEDULE_DAYS',
    CHANGE_JOB_SCHEDULE_TIMES: 'CHANGE_JOB_SCHEDULE_TIMES',

    GET_SCHEDULE_HISTORY_REQUEST: 'GET_SCHEDULE_HISTORY_REQUEST',
    GET_SCHEDULE_HISTORY_SUCCESS: 'GET_SCHEDULE_HISTORY_SUCCESS',
    GET_SCHEDULE_HISTORY_ERROR: 'GET_SCHEDULE_HISTORY_ERROR',
} as const

export type DaysPicked = {
    is_monday: boolean
    is_tuesday: boolean
    is_wednesday: boolean
    is_thursday: boolean
    is_friday: boolean
    is_saturday: boolean
    is_sunday: boolean
}

export interface ISchedule {
    is_active: boolean
    period: {
        from: string | null
        to: string | null
    }
    days_of_week: DaysPicked
    start_time: Array<string | null>
}

interface IStatusHistory {
    status_change_id: string
    status: ProductStatus
    updated_at: string
}

interface IProductsStatusHistory {
    hasMore: boolean
    historyRecords: Array<IStatusHistory>
}

export interface ISchedulesReducer {
    jobSchedules: {
        isLoading: boolean
        error: string
        schedules: Record<string /* guid */, Array<ISchedule>>
    },
    schedulesHistory: {
        isLoading: boolean
        error: string
        productsStatusHistory: Record<string /* guid */, IProductsStatusHistory>
    }
}



