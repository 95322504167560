import unavailableImg from '../../assets/images/cloudSlashIcon.png'
import { Nav } from 'react-bootstrap'
import styles from './index.module.scss'

interface ContentUnavailableProps {
    link: string
    linkDisplayName: string
    textMessage: string
}

export const ContentUnavailable = (props: ContentUnavailableProps) => {
    const { link, linkDisplayName, textMessage } = props

    return (
        <div className="w-100 h-100 d-flex flex-column gap-4 justify-content-center align-items-center">
            <img
                src={unavailableImg}
                alt="news unavailable image"
            />
            <p>{textMessage}</p>
            <Nav.Link
                className={`${styles.link} text-decoration-none p-0`}
                href={link}
                target="_blank"
                rel="noreferrer"
            >
                {linkDisplayName}
            </Nav.Link>
        </div>
    )
}