import { StoreSchema } from '../../types/store'
import { createSelector } from 'reselect'

export const selectJobSchedulesState = (store: StoreSchema) =>
    store.schedules.jobSchedules

export const selectAllProductsJobSchedules = (store: StoreSchema) =>
    store.schedules.jobSchedules.schedules

export const selectProductJobScheduleByGuid = (guid: string) =>
    createSelector(
        [selectAllProductsJobSchedules],
        (schedules) => schedules[guid]
    )

export const selectProductJobScheduleItem = (guid: string, index: number) =>
    createSelector(
        [selectAllProductsJobSchedules],
        (schedules) => schedules[guid]?.[index]
    )


// ===== schedules history ====== //
export const selectSchedulesHistoryState = (store: StoreSchema) => store.schedules.schedulesHistory

export const selectProductsStatusHistory = (store: StoreSchema) =>
    store.schedules.schedulesHistory.productsStatusHistory

export const selectProductStatusHistoryRecords = (guid: string) =>
    createSelector(
        [selectProductsStatusHistory],
        (productsStatusHistory) => productsStatusHistory[guid]
    )
