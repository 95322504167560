import { BlockArrayTypes } from '../types'
import { isTableView } from './isTableView'
import { isChipList } from './isChipList'
import { isCards } from './isCards'
import { isSchedules } from './isSchedules'

export const blockArrayTypeCheck = (
    data: any,
    model: string,
    tableView?: boolean
) => {
    if (isTableView(data, model, tableView)) {
        return BlockArrayTypes.TABLE_VIEW
    }

    if (isSchedules(model)) {
        return BlockArrayTypes.SCHEDULES
    }

    if (isCards(data)) {
        return BlockArrayTypes.CARDS
    }

    if (isChipList(data)) {
        return BlockArrayTypes.CHIP_LIST
    }

    return BlockArrayTypes.TABLE
}
