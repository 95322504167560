import { NewsItemSkeleton } from './NewsItemSkeleton'

export const NewsSkeletons = () => {
    return (
        <div className={`d-flex flex-column gap-4 w-100 h-100`}>
            <div className="d-flex gap-4">
                <NewsItemSkeleton />
                <NewsItemSkeleton />
                <NewsItemSkeleton />
                <NewsItemSkeleton />
            </div>
            <div className="d-flex gap-4">
                <NewsItemSkeleton />
                <NewsItemSkeleton />
                <NewsItemSkeleton />
                <NewsItemSkeleton />
            </div>
        </div>
    )
}