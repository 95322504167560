import { Nav, Tab } from 'react-bootstrap'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import styles from './ScheduleBar.module.scss'
import { ScheduleList } from './ScheduleList/ScheduleList'
import { StatusHistory } from './StatusHistory/StatusHistory'
import { useEffect } from 'react'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { refreshSchedulesCommonState } from '../../store/actions/schedulesActions'
import { ProductTime } from '../ProductTime/ProductTime'

interface ScheduleBarProps {
    guid: string
}

export const ScheduleBar = (props: ScheduleBarProps) => {
    const { guid } = props
    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(refreshSchedulesCommonState())
        }
    }, [dispatch])

    return (
        <div className="app-light-color pt-3">
            <Tab.Container
                defaultActiveKey="Schedule"
                id="controlled-tab"
            >
                <div className="px-4 nav-dark d-flex justify-content-between">
                    <Nav
                        variant="tabs"
                        defaultActiveKey="Schedule"
                    >
                        <Nav.Item>
                            <Nav.Link
                                eventKey="Schedule"
                                className={styles.tabName}
                            >
                                Schedule
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="Launch history"
                                className={styles.tabName}
                            >
                                Launch history
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <ProductTime
                        guid={guid}
                        className="align-self-center"
                    />
                </div>
                <div className={styles.scheduleBarBody}>
                    <OverlayScrollbarsComponent
                        className="p-4"
                        style={{ height: 'calc(100vh - 7.25rem)' }}
                    >
                        <Tab.Content>
                            <Tab.Pane eventKey="Schedule">
                                <ScheduleList guid={guid} />
                            </Tab.Pane>
                        </Tab.Content>
                        <Tab.Content>
                            <Tab.Pane eventKey="Launch history">
                                <StatusHistory guid={guid} />
                            </Tab.Pane>
                        </Tab.Content>
                    </OverlayScrollbarsComponent>
                </div>
            </Tab.Container>
        </div>
    )
}
