import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import styles from './AddTimeBtn.module.scss'

interface AddTimeBtnProps {
    handleTimeAdd: () => void
    className?: string
}

export const AddTimeBtn = (props: AddTimeBtnProps) => {
    const { handleTimeAdd, className } = props

    return (
        <button
            className={`btn-clean d-flex gap-2 align-items-center pe-1 btn ${styles.addTimeBtn} ${className}`}
            onClick={handleTimeAdd}
        >
            <FontAwesomeIcon icon={faPlus} />
            <span className="nowrap">Add time</span>
        </button>
    )
}
