import React, { MouseEvent, useState } from 'react'
import {
    buildControlsExt,
    integerInput,
    selectInput,
} from '../../inputs/controls'
import { coreSettingsErrors } from '../utils/coreSettingsErrors'
import { ProductStatusOnStart } from '../../../types/coreSettings'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { getCoreSettingsValidationSchema } from '../utils/getCoreSettingsValidationSchema'
import * as yup from 'yup'
import { useEffectAfterMount } from '../../../hooks/useEffectAfterMount'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import {
    modifyCoreSettings,
    updateProductCoreSettings,
} from '../../../store/actions/coreSettingsActions'

interface CoreSettingsFieldsProps {
    guid: string
    productCoreSettings: any
}

export const CoreSettingsFields = (props: CoreSettingsFieldsProps) => {
    const { guid, productCoreSettings } = props
    const dispatch = useAppDispatch()

    const [isSaveDisabled, setIsSaveDisabled] = useState(false)

    const coreSettingsValidationSchema = getCoreSettingsValidationSchema()

    const [state, setState, touched, setTouched, validationResult] =
        useFormValidation(productCoreSettings, coreSettingsValidationSchema)

    const checkSchema = yup.object().shape(coreSettingsValidationSchema)
    const isFromValid = checkSchema.isValidSync(state)

    const handleSave = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setIsSaveDisabled(true)
        dispatch(updateProductCoreSettings(guid, state))
        setTimeout(() => setIsSaveDisabled(false), 3000)
    }

    const dataArr = buildControlsExt(
        [
            integerInput({
                name: 'restart_attempts',
                isColumn: true,
                label: 'Restart attempts',
                errMsg: coreSettingsErrors.restartAttempts(state),
                className: 'w-100',
                tooltipText: 'Number of application restart attempts',
                placeholder: 'Default value',
            }),
            integerInput({
                name: 'restart_delay_sec',
                isColumn: true,
                label: 'Restart delay sec',
                errMsg: coreSettingsErrors.restartDelaySec(state),
                className: 'w-100',
                tooltipText: 'Delay in seconds between each restart attempt',
                placeholder: 'Default value',
            }),
            selectInput({
                name: 'product_status_on_start',
                options: Object.keys(ProductStatusOnStart),
                selected: state?.['product_status_on_start'] || Object.keys(ProductStatusOnStart)[0],
                isColumn: true,
                label: 'Product status on start',
            }),
        ],
        state,
        setState,
        '',
        touched,
        setTouched,
        validationResult
    )

    useEffectAfterMount(() => {
        dispatch(modifyCoreSettings({ guid, data: state }))
    }, [state])

    return (
        <div className="d-flex flex-column gap-3 p-4">
            {!!dataArr.length &&
                dataArr.map((el, i) => <div key={i}>{el}</div>)}
            <button
                type="button"
                onClick={handleSave}
                className="app-btn-main app-btn-apply fit-content mt-2"
                disabled={isSaveDisabled || !touched || !isFromValid}
            >
                Save
            </button>
        </div>
    )
}
