import axios from 'axios'
import { Dispatch } from 'redux'
import { UsersActionTypes } from '../../types/users'
import { alertErrorMessage, throwSuccessMessage } from '../../utils/Errors'
import { apiVersion } from '../../versions'
import { hideModal } from './modalActions'
import { getHeaders } from './http'
import { IUser, IUserBackend } from '../../entity/User'

const API_USERS = `/api/${apiVersion}/users`

export const fetchUsers = (): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch({ type: UsersActionTypes.FETCH_USERS })
            const { data } = await axios.get<IUserBackend>(API_USERS, {
                headers: getHeaders(),
            })
            dispatch({
                type: UsersActionTypes.FETCH_USERS_SUCCESS,
                payload: data,
            })
        } catch (e: any) {
            dispatch({
                type: UsersActionTypes.FETCH_USERS_ERROR,
                payload: 'Fetching error',
            })

            alertErrorMessage(e)
        }
    }
}

export const deleteUser = (user: IUser): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            await axios.delete(`${API_USERS}/${user.login}`, {
                headers: getHeaders({ login: user.login }),
            })
            throwSuccessMessage(`Success! ${user.login} deleted!`)
            dispatch({
                type: UsersActionTypes.DELETE_USER_SUCCESS,
                payload: { login: user.login },
            })
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}

export const modifyUser = (user: IUser, index?: number): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            await axios.put(`${API_USERS}/${user.login}`, user, {
                headers: getHeaders(),
            })
            throwSuccessMessage(`Success!`)
            dispatch({
                type: UsersActionTypes.MODIFY_USER_SUCCESS,
                payload: { user, index },
            })
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}

export const modifyCurrentUser = (data: any, path: string): any => {
    return async () => {
        try {
            await axios.post(`${API_USERS}${path}`, data, {
                headers: getHeaders(),
            })
            throwSuccessMessage(`Success!`)
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}

export const createNewUser = (
    user: any,
    setState: any,
    initialState: any,
    setTouched: any,
): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            await axios.post(`${API_USERS}`, user, {
                headers: getHeaders(),
            })
            throwSuccessMessage(`Success!`)
            dispatch({
                type: UsersActionTypes.ADD_NEW_USER_SUCCESS,
                payload: { user },
            })
            dispatch(hideModal())
            setState(initialState)
            setTouched({})
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}
