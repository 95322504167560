export const CoreSettingsActionType = {
    GET_CORE_SETTINGS_REQUEST: 'GET_CORE_SETTINGS_REQUEST',
    GET_CORE_SETTINGS_SUCCESS: 'GET_CORE_SETTINGS_SUCCESS',
    GET_CORE_SETTINGS_ERROR: 'GET_CORE_SETTINGS_ERROR',
    MODIFY_CORE_SETTINGS: 'MODIFY_CORE_SETTINGS',
}

export const ProductStatusOnStart = {
    auto: 'auto',
    active: 'active',
    on_standby: 'on_standby',
    last_received_from_toolbox: 'last_received_from_toolbox'
} as const

export interface ICoreSettings {
    restart_attempts: number
    restart_delay_sec: number
    product_status_on_start: keyof typeof ProductStatusOnStart
}

export interface ICoreSettingsReducer {
    isLoading: boolean
    error: string
    productsCoreSettings: Record<string /* guid */, ICoreSettings>
}

type ProductStatusOnStartBackend = Exclude<keyof typeof ProductStatusOnStart, 'on_standby'> | 'idle'

export interface CoreSettingsBackend extends Omit<ICoreSettings, 'product_status_on_start'> {
    product_status_on_start: ProductStatusOnStartBackend
}