import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { AuthActionType } from '../../../types/auth'
import style from './Styles/index.module.scss'
import { signOut } from '../../../utils/authentication'

export const LogoutButton = () => {
    const dispatch = useDispatch()

    const onLogout = (): void => {
        dispatch({ type: AuthActionType.SIGN_OUT })
        signOut()
    }

    return (
        <button
            type="button"
            onClick={onLogout}
            className={style.button}
        >
            <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                size="lg"
            />
        </button>
    )
}