import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar'
import { faClock } from '@fortawesome/free-regular-svg-icons'

const icons = {
    date: faCalendar,
    time: faClock,
}

interface PeriodPickerProps {
    periodType: 'date' | 'time'
    dateFrom: Date | null | undefined
    dateTo: Date | null | undefined
    onDateFromChange: (date: Date) => void
    onDateToChange: (date: Date | null) => void
    placeholderFrom?: string
    placeholderTo?: string
    className?: string
    containerClassName?: string
}

export const PeriodPicker = (props: PeriodPickerProps) => {
    const {
        dateFrom,
        dateTo,
        onDateFromChange,
        onDateToChange,
        periodType,
        className,
        containerClassName,
    } = props

    const isTime = periodType === 'time'
    const inputFormat = isTime ? 'HH:mm:ss' : 'yyyy-MM-dd'

    if (
        dateFrom &&
        dateTo &&
        !isTime &&
        dateFrom.getTime() > dateTo.getTime()
    ) {
        onDateToChange(null)
    }

    const handleDateFromChange = (date: Date) => {
        onDateFromChange(date)
    }
    const handleDateTo = (date: Date) => {
        onDateToChange(date)
    }

    return (
        <div className={`d-flex gap-2 ${containerClassName}`}>
            <DatePicker
                className={` ${className}`}
                wrapperClassName={`${className}`}
                enableTabLoop={false}
                selectsStart
                selected={dateFrom}
                onChange={handleDateFromChange}
                startDate={dateFrom}
                endDate={dateTo}
                showTimeSelect={isTime}
                showTimeSelectOnly={isTime}
                dateFormat={inputFormat}
                timeFormat={inputFormat}
                timeIntervals={5}
                placeholderText="From"
                showIcon
                icon={
                    <FontAwesomeIcon
                        icon={icons[periodType]}
                        color="grey"
                    />
                }
            />
            <strong style={{ alignSelf: 'center' }}>-</strong>
            <DatePicker
                className={` ${className}`}
                wrapperClassName={`${className}`}
                enableTabLoop={false}
                selected={dateTo}
                onChange={handleDateTo}
                selectsEnd
                startDate={dateFrom}
                endDate={dateTo}
                minDate={dateFrom}
                showTimeSelect={isTime}
                showTimeSelectOnly={isTime}
                dateFormat={inputFormat}
                timeFormat={inputFormat}
                timeIntervals={5}
                placeholderText="To"
                showIcon
                icon={
                    <FontAwesomeIcon
                        icon={icons[periodType]}
                        color="grey"
                    />
                }
            />
        </div>
    )
}
