import React, { FC, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import { IIntegerInput } from './types'
import ErrMessageTooltip from '../Errors/ErrMessageTooltip'

const IntegerInput: FC<IIntegerInput> = ({
    setState,
    setTouched,
    className,
    state,
    touched,
    errors,
    errMsg,
    name,
    label,
    placeholder,
    isDisabled,
    tooltipText,
    isColumn,
    isLabelHidden,
    type,
    isRequired,
    isFeedbackFixed,
    onEnter,
    onBlur,
}) => {
    const [showIntOnlyTooltip, setShowIntOnlyTooltip] = useState(false)
    const target = useRef(null)
    const isInvalid = touched && touched[name] && errors && errors[name]

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (/^-?\d*$/.test(event.target.value)) {
            setShowIntOnlyTooltip(false)
            setTouched &&
                setTouched((prevTouched: typeof touched) => ({
                    ...prevTouched,
                    [name]: true,
                }))

            setState({
                ...state,
                [name]: event.target.value,
            })
        } else {
            setShowIntOnlyTooltip(true)
        }
    }

    const handleBlur = () => {
        setShowIntOnlyTooltip(false)
        if (typeof onBlur === 'function') {
            onBlur()
            return
        }
        setTouched &&
            setTouched((prevTouched: typeof touched) => ({
                ...prevTouched,
                [name]: true,
            }))
    }

    const handleOnKeyDown = (evt: any) => {
        if (evt.key === 'Enter' && typeof onEnter === 'function') {
            onEnter()
        }
    }

    useEffect(() => {
        if (state?.[name] === '') {
            setState((prevState: typeof state) => ({
                ...prevState,
                [name]: null,
            }))
        }
    }, [state?.[name]]) // eslint-disable-line

    return (
        <Form.Group
            style={{ maxWidth: '600px' }}
            className={`${className} ${
                isInvalid && !isFeedbackFixed && 'mb-4'
            } d-flex flex-1 mt-1 mb-1 justify-content-between align-items-baseline`}
        >
            {label && !isLabelHidden ? (
                <Form.Label
                    style={{ maxWidth: isColumn ? '30%' : 'auto' }}
                    className="label-main mt-1 text-capital pe-2"
                >
                    <span className="label-main-wrapper p-relative">
                        {label}:{' '}
                        {isRequired && (
                            <span className="app-color-text label-required-mark">
                                *
                            </span>
                        )}
                    </span>
                </Form.Label>
            ) : null}
            <span
                style={{ width: '70%' }}
                className={`d-flex ${!isColumn ? 'flex-1' : ''} p-relative`}
            >
                <span className="d-flex align-items-center flex-column w-100">
                    <Form.Control
                        className={`input-main ${
                            tooltipText && 'input-padding'
                        }`}
                        type="string"
                        placeholder={placeholder}
                        value={state?.[name] ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleOnKeyDown}
                        disabled={!!isDisabled}
                        isInvalid={isInvalid}
                        ref={target}
                        name={name}
                    />
                    <Form.Control.Feedback
                        className="input-feedback"
                        type="invalid"
                    >
                        <FormattedMessage
                            id={errMsg?.message || 'field-validation-error'}
                            values={errMsg?.values}
                            tagName="span"
                        />
                    </Form.Control.Feedback>
                </span>
                {tooltipText && (
                    <PromptBtn
                        className="mt-1 input-tooltip"
                        prompt={tooltipText ?? ''}
                    />
                )}
            </span>
            <ErrMessageTooltip
                message="int-only-toast"
                error={showIntOnlyTooltip}
                target={target}
            />
        </Form.Group>
    )
}

export default IntegerInput
