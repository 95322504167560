import { ISchedule } from '../../../types/schedule'

export class Schedule implements ISchedule {
    is_active: boolean
    period: {
        from: string | null
        to: string | null
    }
    days_of_week: {
        is_monday: boolean
        is_tuesday: boolean
        is_wednesday: boolean
        is_thursday: boolean
        is_friday: boolean
        is_saturday: boolean
        is_sunday: boolean
    }
    start_time: Array<string | null>

    constructor() {
        this.is_active = true
        this.period = { from: null, to: null }
        this.days_of_week = {
            is_monday: true,
            is_tuesday: true,
            is_wednesday: true,
            is_thursday: true,
            is_friday: true,
            is_saturday: true,
            is_sunday: true,
        }
        this.start_time = [null]
    }
}
