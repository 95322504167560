import { NotificationsActions } from '../types/notifications'
import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'
import { logOnSignalRMethodTrigger } from './lib/logOnSignalRMethodTrigger'
import { SignalRMethods } from './lib/SignalRMethods'

export const receiveNotifications = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    connection.on(SignalRMethods.NEW_NOTIFICATIONS, (notifications) => {
        logOnSignalRMethodTrigger(
            SignalRMethods.NEW_NOTIFICATIONS,
            notifications
        )
        if (Array.isArray(notifications)) {
            dispatch({
                type: NotificationsActions.NEW_NOTIFICATIONS,
                payload: notifications,
            })
        }
    })
}
