import { Dispatch } from 'redux'
import axios from 'axios'
import { getHeaders } from './http'
import { alertErrorMessage, throwSuccessMessage } from '../../utils/Errors'
import { apiVersion } from '../../versions'
import { ActionsActionTypes } from '../../types/actionsTypes'
import fileDownload from 'js-file-download'
import { getContentDispositionFilename } from '../../utils/getContentDispositionFilename'
import { AppDispatch } from '../../types/store'

export const modifyActionParameter =
    (payload: { path: string[]; data: any }) => (dispatch: AppDispatch) => {
        dispatch({
            type: ActionsActionTypes.MODIFY_ACTION_PARAMETER,
            payload,
        })
    }

export const resetActionParameters =
    (payload: { guid: string; actionName: string }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ActionsActionTypes.RESET_ACTION_PARAMETERS,
            payload,
        })
    }

export const setActionInvalid =
    (payload: { path: string[] }) => (dispatch: AppDispatch) => {
        dispatch({
            type: ActionsActionTypes.SET_ACTION_INVALID,
            payload,
        })
    }

export const setActionValid =
    (payload: { path: string[] }) => (dispatch: AppDispatch) => {
        dispatch({
            type: ActionsActionTypes.SET_ACTION_VALID,
            payload,
        })
    }

export const addCardItemToArrayEnd =
    (payload: { path: string[]; data: any }) => (dispatch: AppDispatch) => {
        dispatch({
            type: ActionsActionTypes.ADD_CARD_ITEM_TO_ARRAY_END,
            payload,
        })
    }

export const addCardItemToArrayBeginning =
    (payload: { path: string[]; data: any }) => (dispatch: AppDispatch) => {
        dispatch({
            type: ActionsActionTypes.ADD_CARD_ITEM_TO_ARRAY_BEGINNING,
            payload,
        })
    }

export const deleteItemFromArray =
    (payload: { path: string[]}) => (dispatch: AppDispatch) => {
        dispatch({
            type: ActionsActionTypes.DELETE_ITEM_FROM_ARRAY,
            payload,
        })
    }



// ======= action-creators ======= //

export const fetchProductCustomActions = (productId: string): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await axios.get(`/api/${apiVersion}/products/${productId}/custom_actions`, {
                headers: getHeaders(),
            })
            dispatch({
                type: ActionsActionTypes.GET_CUSTOM_ACTIONS_SUCCESS,
                payload: { guid: productId, customActions: data },
            })
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}

export const fetchProductSystemActions = (productId: string): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await axios.get(`/api/${apiVersion}/products/${productId}/system_actions`, {
                headers: getHeaders(),
            })
            dispatch({
                type: ActionsActionTypes.GET_SYSTEM_ACTIONS_SUCCESS,
                payload: { guid: productId, systemActions: data },
            })
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}

export const fetchProductActionsHistory = (guid: string): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await axios.get(`/api/${apiVersion}/actions`, {
                headers: getHeaders(),
                params: {
                    product_id: guid,
                },
            })
            dispatch({
                type: ActionsActionTypes.GET_ACTIONS_HISTORY,
                payload: { guid, productActionsHistory: data },
            })
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}

export const postAction = (
    product_id: string,
    action_name: string,
    action_params: Record<string, unknown> | undefined = {},
    client_payload?: Record<string, unknown>
): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await axios.post(
                `/api/${apiVersion}/actions`,
                {
                    product_id,
                    action_name,
                    action_params,
                    client_payload,
                },
                {
                    headers: getHeaders(),
                }
            )
            dispatch({
                type: ActionsActionTypes.ADD_ACTION_RESULT,
                payload: {guid: product_id, actionResult: data }
            })
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}

export const downloadActionResult = (actionId: string): any => {
    return async () => {
        try {
            const response = await axios.get(
                `/api/${apiVersion}/actions/${actionId}/result`,
                {
                    headers: getHeaders(),
                    responseType: 'blob',
                }
            )

            if (response.status === 200) {
                const filename = getContentDispositionFilename(response)
                fileDownload(response.data, filename)
            }
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}

export const cancelAction = (actionId: string): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            await axios.put(
                `/api/${apiVersion}/actions/${actionId}/status`,
                { action_status: 'canceled' },
                { headers: getHeaders() }
            )
            throwSuccessMessage('Success')
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}
