import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { useEffect, useRef } from 'react'
import { useLocalStorage } from '../../hooks/useLocalStorage'

interface AppRightBarProps {
    hidden: boolean
    items: React.JSX.Element[] | React.JSX.Element | null
    onClick(): void
    className?: string
    nonResizable?: boolean
}

export const AppRightBar = (props: AppRightBarProps) => {
    const { hidden, items, onClick, className, nonResizable } = props

    const backdropActive = hidden ? '' : 'app-rightbar-backdrop-active'

    const [width, setWidth] = useLocalStorage('appRightBar', 600)

    const ref = useRef(
        null
    ) as unknown as React.MutableRefObject<HTMLDivElement>
    const refLeft = useRef(
        null
    ) as unknown as React.MutableRefObject<HTMLDivElement>
    const refOverlay = useRef(
        null
    ) as unknown as React.MutableRefObject<HTMLDivElement>

    useEffect(() => {
        if (nonResizable) return

        const resizeableElement = ref.current
        const refElemStyles = window.getComputedStyle(resizeableElement)
        let width = parseInt(refElemStyles.width, 10)
        let xCord = 0
        let resizeTimer: any

        const onMouseMoveLeftResize = (event: MouseEvent) => {
            const dx = event.clientX - xCord
            width = width - dx
            xCord = event.clientX
            setWidth(width < 600 ? 600 : width)
        }

        const onMouseUpLeftResize = () => {
            document.removeEventListener('mousemove', onMouseMoveLeftResize)
            document.body.style.userSelect = 'auto'
            document.body.style.cursor = 'auto'
            refOverlay.current.style.cssText = ''
        }

        const onMouseDownLeftResize = (event: MouseEvent) => {
            clearTimeout(resizeTimer)
            resizeTimer = setTimeout(() => {
                xCord = event.clientX
                const refElemStyles = window.getComputedStyle(resizeableElement)
                document.body.style.userSelect = 'none'
                resizeableElement.style.right = refElemStyles.right
                // @ts-ignore
                resizeableElement.style.left = null
                document.addEventListener('mouseup', onMouseUpLeftResize)
                document.addEventListener('mousemove', onMouseMoveLeftResize)
                document.body.style.cursor = 'ew-resize'
                refOverlay.current.style.cssText = `
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 10;
                        opacity: 0.5;
                    `
            }, 50)
        }

        const resizerLeft = refLeft.current as unknown as HTMLElement
        resizerLeft!.addEventListener('mousedown', onMouseDownLeftResize)

        return () => {
            resizerLeft.removeEventListener('mousedown', onMouseDownLeftResize)
        }
    }, [])

    return (
        <>
            <div
                className={`app-rightbar-backdrop ${backdropActive}`}
                onClick={onClick}
            />
            <div
                ref={ref}
                style={{ width: nonResizable ? '' : `${width}px` }}
                className={`app-rightbar ${className}`}
            >
                {!nonResizable && (
                    <div
                        ref={refLeft}
                        className="app-rightbar-resizer"
                    />
                )}
                <OverlayScrollbarsComponent
                    style={{
                        marginLeft: nonResizable ? '' : '5px',
                        height: 'calc(100vh - 58px)',
                        position: 'relative',
                    }}
                >
                    <div ref={refOverlay}></div>
                    <div>{items}</div>
                </OverlayScrollbarsComponent>
            </div>
        </>
    )
}
