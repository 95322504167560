import { SignalRMethods } from './SignalRMethods'

export const logOnSignalRMethodTrigger = (
    method: SignalRMethods,
    ...payload: any[]
) => {
    console.info(
        `SignalR method %c${method}%c triggerred. Payload received:\n ${JSON.stringify(
            payload
        )}`,
        'font-weight: bold; font-style: italic'
    )
}
