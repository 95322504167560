import * as yup from 'yup'
import { IUser, IUserBackend, User, UserRoles } from '../../../entity/User'
import { createUserRoles } from '../../../utils/userRights'

export interface IUserAccount extends Omit<IUserBackend, 'permissions'> {
    login: string
    newPassword: ''
    confirmPassword: ''
    role: UserRoles
}

export const getUserAccountFields = (user: IUserBackend): IUserAccount => ({
    login: user.login,
    newPassword: '',
    confirmPassword: '',
    role: User.getRole(user),
    visibility: user.visibility ?? User.createEmptyVisibility(),
})

export const getUserAccountValidationSchema = () => ({
    login: yup.string().required(),
    newPassword: yup
        .string()
        .min(5)
        .notRequired()
        .nullable()
        .transform((_: any, val: any) => String(val) || null),
    confirmPassword: yup
        .string()
        .min(5)
        .notRequired()
        .notRequired()
        .nullable()
        .transform((_: any, val: any) => String(val) || null),
})

export const getOptions = (
    administratedUserRole: UserRoles,
    authUser: User,
    isReadOnly: boolean
) =>
    administratedUserRole === UserRoles.NOT_STANDARD_ROLE
        ? [UserRoles.NOT_STANDARD_ROLE, ...createUserRoles(authUser)]
        : [...createUserRoles(authUser, isReadOnly)]

export const isOwnerNonEditable = (
    currentUser: User,
    administeredUser: IUser
) => {
    const currentUserType = currentUser.getRole()
    const administratedUserType = User.getRole(administeredUser)

    return (
        administratedUserType === UserRoles.OWNER &&
        currentUserType !== UserRoles.OWNER
    )
}