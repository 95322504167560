import { User } from '../../../entity/User'
import * as yup from 'yup'

type AuthAccount = {
    login: string
    oldPassword: string
    newPassword: string
    confirmPassword: string
}

export const getAuthAccountFields = (user: User): AuthAccount => ({
    login: user.login,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
})

export const getAuthAccountValidationSchema = () => ({
    login: yup.string().required(),
    oldPassword: yup.string().min(5).required(),
    newPassword: yup.string().min(5).required(),
    confirmPassword: yup.string().min(5).required(),
})