import React from 'react'
import { componentConstructor } from '../../GUIConstructor/control'
import { AdjustableRange } from './AdjustableRange/AdjustableRange'
import { BlockModels } from './types'

export const blockConstructor = (
    dataSchema: any,
    data: any,
    dataProp: any,
    name: string,
    childrenPath: string[],
    dataPath: string[],
    blockState: boolean,
    levelDeep = 1,
    element_id?: string,
    inCard?: boolean,
    isRuleActive?: boolean,
    pathWithElementID?: string[],
    builderType?: 'actionUiBuilder' | 'configUiBuilder'
) => {
    switch (dataSchema?.model) {
        case BlockModels.ADJUSTABLE_RANGE:
            return (
                <AdjustableRange
                    blockState={blockState}
                    childrenPath={childrenPath}
                    dataProp={dataProp}
                    data={data}
                    dataSchema={dataSchema}
                    name={name}
                    dataPath={dataPath}
                    levelDeep={levelDeep + 1}
                    element_id={element_id}
                    inCard={inCard}
                    isRuleActive={isRuleActive}
                    pathWithElementID={pathWithElementID}
                    builderType={builderType}
                />
            )

        default:
            return componentConstructor(
                dataSchema,
                Object.keys(data || {}).length ? data : dataProp,
                name,
                'block',
                '',
                childrenPath,
                undefined,
                !blockState,
                levelDeep + 1,
                element_id,
                inCard,
                isRuleActive,
                pathWithElementID,
                builderType
            )
    }
}
