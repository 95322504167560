import { Card, Placeholder } from 'react-bootstrap'

export const FactsSkeleton = () => {
    return (
        <div className="d-flex flex-shrink-0 gap-4 w-100">
            {['24', '42'].map((fact42) => (
                <Card
                    key={fact42}
                    as={'div'}
                    className="w-100"
                >
                    <Card.Body>
                        <Placeholder
                            as={Card.Title}
                            animation="glow"
                        >
                            <Placeholder xs={6} />
                        </Placeholder>
                        <Placeholder
                            as={Card.Text}
                            animation="glow"
                        >
                            <Placeholder xs={7} /> <Placeholder xs={4} />{' '}
                            <Placeholder xs={4} /> <Placeholder xs={6} />{' '}
                            <Placeholder xs={8} />
                        </Placeholder>
                        <Placeholder xs={6} />
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}