import { IProduct } from '../types/product'

export enum ProductType {
    PLUGIN = 'plugin',
    SERVICE = 'service',
    JOB = 'job',
}

export enum ProductStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    IDLE = 'idle',
    CHANGING_TO_ACTIVE = 'changing_to_active',
    CHANGING_TO_IDLE = 'changing_to_idle',
}

export class ProductEntity implements IProduct {
    status: ProductStatus
    guid: string
    name: string
    version: string
    ip: string
    path: string
    display_name?: string
    type?: ProductType

    constructor(product: any) {
        this.status = product.status ?? ''
        this.guid = product.guid ?? ''
        this.name = product.name ?? ''
        this.version = product.version ?? ''
        this.ip = product.ip ?? ''
        this.path = product.path ?? ''
        this.display_name = product.display_name || undefined
        this.type = product.type || undefined
    }
}
