import style from '../NotificationsModal.module.scss'
import { INotification } from '../../../../types/notifications'
import React, { useEffect, useRef, useState } from 'react'
import { checkOverflow } from '../../../../utils/checkOverflow'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { MarkNotificationBtn } from '../MarkNotificationBtn/MarkNotificationBtn'
import { ViewNotificationBtn } from './ViewNotificationBtn/ViewNotificationBtn'

const userLocale = navigator.language || 'en-US'

interface NotificationProps {
    notification: INotification
}

export const Notification = (props: NotificationProps) => {
    const { notification } = props

    const notificationRef = useRef<HTMLSpanElement>(null)
    const [viewMore, setViewMore] = useState(false)
    const [showViewMore, setShowViewMore] = useState(false)
    const { productsIdDisplayNameDictionary } = useTypedSelector(
        (store) => store.products
    )

    const notificationTime = new Date(notification.created_at).toLocaleString(
        userLocale
    )
    const isRead = notification.status === 'read'
    const productName = `${
        productsIdDisplayNameDictionary[notification.product_id]
    }:`

    const handleViewMore = () => {
        setViewMore((prev) => !prev)
    }

    useEffect(() => {
        if (notificationRef.current) {
            const hasOverflowItems = checkOverflow(notificationRef.current)
            const isOverMH = notificationRef.current?.scrollHeight > 45
            setShowViewMore(hasOverflowItems || isOverMH)
        }
    }, [])

    return (
        <span
            key={notification.notification_id}
            className={style.notis}
        >
            <MarkNotificationBtn notification={notification} />
            <span className={style.notisContent}>
                <span className={style.notisHead}>
                    <span className={style.notisTime}>{notificationTime}</span>
                </span>
                <span
                    ref={notificationRef}
                    className={`${style.notisText} ${isRead && style.read} ${
                        !viewMore && style.notisMaxHeight
                    }`}
                >
                    <span className={style.productName}>{productName}</span>
                    {notification.message}
                </span>
                {showViewMore && (
                    <ViewNotificationBtn
                        viewMore={viewMore}
                        onViewMore={handleViewMore}
                    />
                )}
            </span>
        </span>
    )
}
