import { ButtonWithTooltip } from '../ButtonWithTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset } from '@fortawesome/free-solid-svg-icons/faHeadset'
import { Link } from 'react-router-dom'

export const CustomerPortalBtn = () => {
    return (
        <ButtonWithTooltip
            tooltip="Open TFB customer portal"
            tooltipPlacement="bottom"
        >
            <button className="mr-3 btn-clean">
                <Link
                    className="navLink text-white"
                    to={{
                        pathname:
                            'https://tools4brokers.atlassian.net/servicedesk/customer/portals',
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <FontAwesomeIcon icon={faHeadset} />
                </Link>
            </button>
        </ButtonWithTooltip>
    )
}