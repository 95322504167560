import { Card, Placeholder } from 'react-bootstrap'
import ImgLoading from '../../../assets/images/imgLoading.jpg'

export const NewsItemSkeleton = () => {
    return (
        <Card
            as="div"
            className="w-100 p-4 gap-3"
        >
            <Card.Img
                variant="top"
                src={ImgLoading}
            />
            <Card.Body>
                <Placeholder
                    as={Card.Title}
                    animation="glow"
                >
                    <Placeholder xs={6} />
                </Placeholder>
                <Placeholder
                    as={Card.Text}
                    animation="glow"
                >
                    <Placeholder xs={7} /> <Placeholder xs={4} />{' '}
                    <Placeholder xs={4} /> <Placeholder xs={6} />{' '}
                    <Placeholder xs={8} />
                </Placeholder>
                <Placeholder xs={6} />
            </Card.Body>
        </Card>
    )
}