import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { modifyCurrentUser } from '../../../store/actions/usersActions'
import { controllerErrors } from '../../../utils/validationErrors/controllerErrors'
import { buildControlsExt, passwordInput } from '../../inputs/controls'
import { User, UserRoles } from '../../../entity/User'
import { getLabel } from '../../../validators'
import { FormattedMessage } from 'react-intl'
import { getJwt } from '../../../utils/authentication'
import { getAuthAccountFields, getAuthAccountValidationSchema } from './helpers'

export const Account = () => {
    const dispatch = useDispatch()
    const authUser = new User()
    const [openUsers, setOpenUsers] = useState<boolean>(false)
    const accountFields = getAuthAccountFields(authUser)
    const schema = getAuthAccountValidationSchema()
    const userRole = User.getRole(authUser)
    const [inputState, setInputState, touched, setTouched, validationResult] =
        useFormValidation(
            accountFields,
            schema,
            'newPassword',
            'confirmPassword',
            'oldPassword'
        )
    const IsAbleToConfirm: boolean = !!Object.keys(validationResult).length

    const handleReset = (): void => {
        setInputState(accountFields)
        setTouched({})
        setOpenUsers(false)
    }

    const passwordInputs = buildControlsExt(
        [
            passwordInput({
                name: 'oldPassword',
                label: 'user.account.currentPassword',
                isColumn: true,
                className: 'mb-2',
                isRequired: true,
                inputType: 'password',
                errMsg: controllerErrors.adminControllerOldPassword(inputState),
            }),
            passwordInput({
                name: 'newPassword',
                label: 'user.account.newPassword',
                isColumn: true,
                className: 'mb-2',
                isRequired: true,
                inputType: 'password',
                errMsg: controllerErrors.adminControllerNewPassword(inputState),
            }),
            passwordInput({
                name: 'confirmPassword',
                label: 'user.account.confirmPassword',
                isColumn: true,
                className: 'mb-2',
                isRequired: true,
                inputType: 'password',
                errMsg: controllerErrors.adminControllerRepeatPassword(
                    inputState
                ),
            }),
        ],
        inputState,
        setInputState,
        '',
        touched,
        setTouched,
        validationResult
    )

    const handleOnApply = (): void => {
        const user = {
            access_token: getJwt(),
            oldPassword: inputState.oldPassword,
            newPassword: inputState.newPassword,
        }
        handleReset()
        dispatch(modifyCurrentUser(user, '/current/change_password'))
    }

    const getTouched = (): boolean => {
        if (openUsers) return false
        return JSON.stringify(inputState) === JSON.stringify(accountFields)
    }

    const isTouched = getTouched()

    return (
        <div className="d-flex">
            <Card style={{ minWidth: '450px' }}>
                <Card.Header>
                    <h3 className="app-color-text">{authUser.login}</h3>
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                        <div className="d-flex flex-column border-bottom">
                            <p className="navi-blue mb-3 h5 d-flex align-items-center">
                                <span className="d-block app-w-30">
                                    <FormattedMessage id="user.account.role" />
                                </span>
                                <span className="label-main d-block text-capitalize">
                                    {userRole}
                                </span>
                            </p>
                            {userRole === UserRoles.NOT_STANDARD_ROLE && (
                                <p className="navi-blue mb-3 d-flex align-items-center">
                                    <span className="d-block h5 app-w-30">
                                        <FormattedMessage id="user.account.permissions" />
                                    </span>
                                    <span className="label-main d-flex flex-column text-capitalize">
                                        {authUser.permissions.map(
                                            (
                                                permission: string,
                                                index: number
                                            ) => (
                                                <span key={permission}>
                                                    {getLabel(permission)}
                                                    {index + 1 !==
                                                        authUser.permissions
                                                            .length && ','}
                                                </span>
                                            )
                                        )}
                                    </span>
                                </p>
                            )}
                        </div>
                    </Card.Title>
                    <div>
                        <p className="navi-blue mb-3 mt-3 h5">
                            <FormattedMessage id="user.account.changePassword" />
                        </p>
                        <div id="example-collapse-text">{passwordInputs}</div>
                    </div>
                </Card.Body>
                <Card.Footer className="mt-4">
                    <Button
                        onClick={handleOnApply}
                        disabled={isTouched || IsAbleToConfirm}
                        variant="primary"
                        className="app-btn-apply"
                    >
                        <FormattedMessage id="buttons.apply" />
                    </Button>
                    <Button
                        disabled={isTouched}
                        onClick={handleReset}
                        className="ms-2"
                        variant="secondary"
                    >
                        <FormattedMessage id="buttons.cancel" />
                    </Button>
                </Card.Footer>
            </Card>
        </div>
    )
}
