import React, { FC, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { getLabel } from '../../../validators'
import { RunTestsButton } from '../RunTestsButton'
import style from './Styles/index.module.scss'
import { IProduct } from '../../../types/product'
import { ProductInfoPopover } from '../../overlays/ProductInfoPopover/ProductInfoPopover'
import { DropdownMore } from '../../overlays/DropdownMore/DropdownMore'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { RightBarActionType } from '../../../types/rightbar'
import { ActionsBar } from '../../ActionsBar/ActionsBar'
import { LogsDropdown } from '../../overlays/LogsDropdown/LogsDropdown'
import ProductCardProductStatus from '../../ProductCard/components/ProductCardProductStatus'
import { User } from '../../../entity/User'
import { selectProductCustomActions } from '../../ActionsBar/selectors/actionsSelectors'
import { ScheduleBtn } from '../ScheduleBtn/ScheduleBtn'

interface IProductButtonsPanel {
    appName?: string
    title: string
    guid: string
    currentProduct: IProduct | undefined
}

const ProductButtonsPanel: FC<IProductButtonsPanel> = ({
    appName,
    title,
    guid,
    currentProduct,
}) => {
    const dispatch = useAppDispatch()
    const authUser = new User()
    const [productName, setProductName] = useState<string>('')

    const productCustomActions = useTypedSelector(
        selectProductCustomActions(guid)
    )
    const isActionsDisabled =
        authUser.isViewer() || !Object.keys(productCustomActions ?? {}).length

    const { status, type } = currentProduct || {}

    useMemo(() => {
        const raw =
            localStorage.getItem(`${guid}-productName`) || getLabel(title)
        setProductName(raw)
    }, []) // eslint-disable-line

    const handleShowActions = () => {
        dispatch({
            type: RightBarActionType.ACTIONS_BAR,
            payload: {
                rightBarItem: <ActionsBar guid={guid} />,
            },
        })
    }

    return (
        <div className={`d-flex px-3 align-items-center ${style.container}`}>
            <div className="d-flex gap-2">
                <h1
                    className={`${style.title} app-color-text mb-1 mt-2 text-capital word-break py-2`}
                >
                    {productName}
                </h1>
                {authUser.isWlOrAbove() && (
                    <ProductInfoPopover
                        className="pt-3"
                        appName={appName}
                        currentProduct={currentProduct}
                    />
                )}
            </div>
            <div className="ml-auto d-flex align-items-center gap-2">
                <ProductCardProductStatus
                    guid={guid}
                    status={status}
                    productType={type}
                    className="me-2"
                    isControlDisabled={authUser.isViewer()}
                />
                <ScheduleBtn
                    guid={guid}
                    isDisabled={authUser.isViewer()}
                />
                {!authUser.isWl() && (
                    <LogsDropdown
                        guid={guid}
                        isDisabled={!authUser.isFullRightsManagerOrAbove()}
                    />
                )}
                <DropdownMore guid={guid} />
                <button
                    type="button"
                    disabled={isActionsDisabled}
                    className={`app-btn-main ${!isActionsDisabled && 'app-btn-reset'} ${
                        isActionsDisabled && style.disabled
                    }`}
                    onClick={handleShowActions}
                >
                    <FormattedMessage id="actions" />
                </button>
            </div>
            <RunTestsButton guid={guid} />
        </div>
    )
}

export default React.memo(ProductButtonsPanel)
