import { BlockArrayModels } from '../types'

export const isTableView = (data: any, model: string, tableView?: boolean) => {
    const isRuleModel = model === BlockArrayModels.RULE
    const hasIsSummary =
        isRuleModel && tableView
            ? data.some((el: any) => el.hasOwnProperty('is_summary'))
            : false

    return isRuleModel && hasIsSummary && tableView
}