import styles from '../index.module.scss'
import { ContentUnavailable } from '../ContentUnavailable'
import { NewsSkeletons } from './NewsSkeletons'
import { Carousel } from 'react-bootstrap'
import { HomePageItem } from '../HomePage'
import { NewsRow } from './NewsRow'

const TFB_NEWS_URL = 'https://t4b.com/news/'

interface NewsProps {
    isLoading: boolean
    isError: boolean
    news: Array<HomePageItem[]>
}

export const News = (props: NewsProps) => {
    const { isError, isLoading, news } = props
    return (
        <div className="d-flex flex-column gap-2 mt-4">
            <div style={{ fontSize: '1.75rem' }}>News</div>
            <div className={`${styles.contentBox} p-5`}>
                {isError ? (
                    <ContentUnavailable
                        link={TFB_NEWS_URL}
                        linkDisplayName={'Open news'}
                        textMessage={
                            'Currently, news unavailable. Please check news on our website'
                        }
                    />
                ) : isLoading ? (
                    <NewsSkeletons />
                ) : (
                    <Carousel controls={false}>
                        {!!news?.length &&
                            news.map((newsItems, idx) => (
                                <Carousel.Item key={idx.toString()}>
                                    <NewsRow newsItems={newsItems} />
                                </Carousel.Item>
                            ))}
                    </Carousel>
                )}
            </div>
        </div>
    )
}