import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'
import { SignalRMethods } from './lib/SignalRMethods'
import { logOnSignalRMethodTrigger } from './lib/logOnSignalRMethodTrigger'
import { ActionsActionTypes, IActionHistory } from '../types/actionsTypes'

export const receiveActionUpdate = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    connection.on(SignalRMethods.ACTION_UPDATE, (action: IActionHistory) => {
        logOnSignalRMethodTrigger(SignalRMethods.ACTION_UPDATE, action)

        const { action_id, product_id } = action || {}

        if (action_id && product_id) {
            dispatch({
                type: ActionsActionTypes.UPDATE_ACTION,
                payload: { action },
            })
        }
    })
}