import { User, UserPermissions } from '../entity/User'
import { hashCode } from './math'

export const saveJwt = (jwt: string) => localStorage.setItem('access_jwt', jwt)
export const getJwt = (): string | null => localStorage.getItem('access_jwt')
export const removeJwt = (): void => localStorage.removeItem('access_jwt')

export const tokenRefresh = (refresh: string) =>
    localStorage.setItem('refresh_jwt', refresh)
export const getTokenRefresh = () => localStorage.getItem('refresh_jwt')
export const removeTokenRefresh = () => localStorage.removeItem('refresh_jwt')

export const signOut = () => {
    const user = new User()
    const userHashedLogin = hashCode(user.login)
    localStorage.setItem('prev', JSON.stringify(userHashedLogin))
    removeJwt()
    removeUser()
    removeTokenRefresh()
}

export const getAuthUsername = () => {
    const user = localStorage.getItem('user')
    if (user === null) {
        return 'Unknown'
    }
    return JSON.parse(user).login || ''
}

export const getAuthPermissions = (): UserPermissions[] => {
    if (!localStorage.getItem('user')) return []
    const user = JSON.parse(localStorage.getItem('user') || '')

    const permissions = user?.permissions

    if (!user || !Array.isArray(permissions)) {
        return []
    }

    return permissions
}

export const saveUser = (data: any) => {
    const user = JSON.parse(atob(data.access_token.split('.')[1]))

    localStorage.setItem(
        'user',
        JSON.stringify({
            login: user.name,
            permissions: user.roles,
        }),
    )
}

export const removeUser = () => localStorage.removeItem('user')
