import { ISchedule } from '../../../../types/schedule'
import styles from '../ScheduleItem.module.scss'
import { generateScheduleSummary } from '../../lib/generateScheduleSummary'

interface ScheduleSummaryProps {
    guid: string
    schedule: ISchedule
}

export const ScheduleSummary = (props: ScheduleSummaryProps) => {
    const { schedule } = props
    const summary = generateScheduleSummary(schedule)

    return <div className={`px-4 ${styles.summary}`}>{summary}</div>
}
