import * as yup from 'yup'

export const getCoreSettingsValidationSchema = () => {
    return {
        restart_attempts: yup
            .number()
            .integer()
            .max(Number.MAX_SAFE_INTEGER)
            .min(0)
            .nullable(),
        restart_delay_sec: yup
            .number()
            .integer()
            .max(Number.MAX_SAFE_INTEGER)
            .min(0)
            .nullable(),
        product_status_on_start: yup.string().required(),
    }
}
