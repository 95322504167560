import React from 'react'
import { Card, Nav, Tab } from 'react-bootstrap'
import { useFormValidation } from '../../../../hooks/useFormValidation'
import {
    getUserAccountFields,
    getUserAccountValidationSchema,
    isOwnerNonEditable,
} from '../helpers'
import {
    AllProductsAccessTypes,
    IUserBackend,
    User,
} from '../../../../entity/User'
import { Footer } from './Footer'
import { Information } from './Information'
import { UserPassword } from './UserPassword'
import { ProductsVisibility } from '../../../ProductsVisibility/ProductsVisibility'
import Loader from '../../../Loader/Loader'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { FormattedMessage } from 'react-intl'

interface UserDataProps {
    administeredUser: IUserBackend
    index: number
}

export const UserData = ({ administeredUser, index }: UserDataProps) => {
    const authUser = new User()
    const accountFields = getUserAccountFields(administeredUser)
    const schema = getUserAccountValidationSchema()

    const [inputState, setInputState, touched, setTouched, validationResult] =
        useFormValidation(
            accountFields,
            schema,
            'newPassword',
            'confirmPassword'
        )

    const isAdminedUserAuthUser = administeredUser.login === authUser.login
    const isReadOnly = isOwnerNonEditable(authUser, administeredUser)
    const isAllProductsAccessible = AllProductsAccessTypes?.[inputState.role]

    if (isAdminedUserAuthUser) return <></>

    return (
        <Tab.Pane
            eventKey={administeredUser.login}
            key={administeredUser.login}
        >
            <Card style={{ minWidth: '400px', maxWidth: '700px' }}>
                <Tab.Container
                    id="left-tabs"
                    defaultActiveKey={1}
                >
                    <Card.Header className="nav-dark d-flex">
                        <Nav
                            variant="tabs"
                            className="pointer"
                            defaultActiveKey={1}
                        >
                            <Nav.Item key={1}>
                                <Nav.Link eventKey={1}>
                                    <div className="text-capital">
                                        <FormattedMessage id="user.userData.generalInformation" />
                                    </div>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Nav
                            variant="tabs"
                            className="ml-2 pointer"
                            defaultActiveKey={2}
                        >
                            <Nav.Item key={2}>
                                <Nav.Link eventKey={2}>
                                    <div className="text-capital">
                                        <FormattedMessage id="user.userData.productsVisibility" />
                                    </div>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>
                    <Card className="border-none">
                        <Card.Body className="vh-user-card pt-0 p-0 pb-1 ">
                            <OverlayScrollbarsComponent className="h-100 ps-4 pe-4">
                                <React.Suspense
                                    fallback={
                                        <div className="vh-full d-flex justify-content-center">
                                            <Loader />
                                        </div>
                                    }
                                >
                                    <Tab.Content>
                                        <Tab.Pane
                                            key={1}
                                            eventKey={1}
                                        >
                                            <div>
                                                <Information
                                                    inputState={inputState}
                                                    setInputState={
                                                        setInputState
                                                    }
                                                    administeredUser={
                                                        administeredUser
                                                    }
                                                    authUser={authUser}
                                                    isReadOnly={isReadOnly}
                                                    className="mt-4"
                                                />
                                                <UserPassword
                                                    isReadOnly={isReadOnly}
                                                    inputState={inputState}
                                                    setInputState={
                                                        setInputState
                                                    }
                                                    touched={touched}
                                                    setTouched={setTouched}
                                                    validationResult={
                                                        validationResult
                                                    }
                                                    className="mt-4 pt-2"
                                                />
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <Tab.Content>
                                        <Tab.Pane
                                            key={2}
                                            eventKey={2}
                                        >
                                            <ProductsVisibility
                                                isReadOnly={isReadOnly}
                                                isAllProductsAccessible={
                                                    isAllProductsAccessible
                                                }
                                                inputState={inputState}
                                                setInputState={setInputState}
                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </React.Suspense>
                            </OverlayScrollbarsComponent>
                        </Card.Body>
                    </Card>
                </Tab.Container>
                <Footer
                    isCurrentUser={isAdminedUserAuthUser}
                    inputState={inputState}
                    setInputState={setInputState}
                    setTouched={setTouched}
                    touched={touched}
                    index={index}
                    isReadOnly={isReadOnly}
                    userLocal={accountFields}
                    validationResult={validationResult}
                    user={administeredUser}
                />
            </Card>
        </Tab.Pane>
    )
}
