import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { inputValidationCheck } from '../../../../../validators/inputValidators/inputValidators'
import React, { useEffect, useState } from 'react'
import { useFormValidation } from '../../../../../hooks/useFormValidation'
import { ChipList } from '../../../../models/BlockArray/components/ChipList/ChipList'
import PromptBtn from '../../../../buttons/PromptBtn/PromptBtn'
import { getInputLabel } from '../../../utils/getInputLabel'
import { WrappedBlockACTN } from '../../BlockACTN/WrappedBlockACTN'
import {
    modifyActionParameter,
    setActionInvalid,
    setActionValid,
} from '../../../../../store/actions/actionsActions'

interface ChipListBlockACTNProps {
    data: any
    dataSchema: any
    dataPath: string[]
    name: string
    wrapper?: string
    levelDeep?: number
    element_id?: string
}

export const ChipListBlockACTN = (props: ChipListBlockACTNProps) => {
    const { data, dataSchema, name, wrapper, dataPath, levelDeep, element_id } =
        props
    const {
        type,
        name: inputLabel,
        is_required: isRequired,
        is_empty_as_null: isEmptyAsNull,
        is_auxiliary: isCollapsed,
    } = dataSchema

    const dispatch = useAppDispatch()
    const [path] = useState([...(dataPath || []), name])

    const validationCheck = inputValidationCheck(type, dataSchema)

    const options = (typeof data === 'string' ? data.split(' ') : data || [])
        .map((el: string | number) => ({
            label: String(el),
            value: el,
        }))
        .filter((el: { label: string; value: any }) => el.value)

    const [inputState, setInputState] = useFormValidation(
        { [name]: options },
        { [name]: validationCheck }
    )

    const dataLen = inputState?.[name]?.length
    const label = getInputLabel(name, inputLabel)
    const desc = dataSchema?.children?.description || dataSchema?.description

    useEffect(() => {
        dispatch(
            modifyActionParameter({
                path,
                data: (inputState[name] || []).map(
                    ({ value }: { value: string }) => value
                ),
            })
        )

        if (isRequired && isEmptyAsNull && !dataLen) {
            dispatch(setActionInvalid({ path }))
        }
        if (isRequired && !isEmptyAsNull && !dataLen) {
            dispatch(setActionValid({ path }))
        }
        if (isRequired && isEmptyAsNull && dataLen) {
            dispatch(setActionValid({ path }))
        }
    }, [inputState]) // eslint-disable-line

    return (
        <WrappedBlockACTN
            name={name}
            elements={[
                <ChipList
                    name={name}
                    schema={dataSchema}
                    state={inputState}
                    setState={setInputState}
                />,
            ]}
            wrapper={wrapper}
            title={label}
            buttons={[
                <>
                    {isRequired && isEmptyAsNull && !dataLen && (
                        <PromptBtn
                            className="me-2 mt-1"
                            type="validation"
                            prompt="At least 1 item required"
                        />
                    )}
                    {desc && (
                        <PromptBtn
                            size="small"
                            className="text-light m-1 z-1000"
                            prompt={desc}
                        />
                    )}
                </>,
            ]}
            dataSchema={{
                is_required: true,
                requiredMark: isRequired && isEmptyAsNull,
                is_auxiliary: isCollapsed,
            }}
            dataPath={dataPath}
            levelDeep={levelDeep}
            element_id={element_id}
        />
    )
}
