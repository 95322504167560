import { Nav } from 'react-bootstrap'
import styles from '../index.module.scss'
import { HomePageItem } from '../HomePage'

interface FactCardProps {
    fact: HomePageItem
}

export const FactCard = (props: FactCardProps) => {
    const { fact } = props

    return (
        <div className={`${styles.factCard} d-flex flex-column flex-1 gap-3`}>
            <div className={`${styles.title} mb-2`}>{fact.title}</div>
            <div className="mb-2">{fact.description}</div>
            <Nav.Link
                className={`${styles.link} text-decoration-none p-0`}
                href={fact.link}
                target="_blank"
                rel="noreferrer"
            >
                {fact.linkName}
            </Nav.Link>
        </div>
    )
}
