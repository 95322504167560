import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
    selectProductStatusHistoryRecords,
    selectSchedulesHistoryState,
} from '../../../store/selectors/jobSchedulesSelectors'
import React, { useEffect, useMemo } from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { fetchProductSchedulesHistory } from '../../../store/actions/schedulesActions'
import {
    ColumnDef,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { fuzzyFilter } from '../../../utils/fuzzyFilter'
import Loader from '../../Loader/Loader'
import styles from '../ScheduleList/ScheduleList.module.scss'
import { FormattedMessage } from 'react-intl'
import { NoData } from '../../NoData'
import { StatusHistoryHeaders } from './StatusHistoryHeaders'
import { StatusHistoryRow } from './StatusHistoryRow'

interface StatusHistoryProps {
    guid: string
}

export const StatusHistory = (props: StatusHistoryProps) => {
    const { guid } = props
    const dispatch = useAppDispatch()

    const { isLoading, error } = useTypedSelector(selectSchedulesHistoryState)
    const { historyRecords } = useTypedSelector(
        selectProductStatusHistoryRecords(guid)
    ) ?? {}

    /*TODO: uncomment when status history is updated in real time*/
    // const handleMore = () => {
    //     const lastHistoryItemID = historyRecords?.at(-1)?.status_change_id ?? ''
    //     dispatch(
    //         fetchProductSchedulesHistory(guid, 'active', lastHistoryItemID)
    //     )
    // }

    const columns = useMemo<ColumnDef<unknown>[]>(
        () => [
            {
                accessorKey: 'status',
                cell: (info) => info.getValue(),
                header: () => 'STATUS',
            },
            {
                accessorKey: 'updated_at',
                cell: (info) => {
                    const date = (info.getValue() as string) ?? ''
                    const result = `${date.slice(0, 10)} ${date.slice(11, 19)}`
                    return result ?? ''
                },
                header: () => 'DATE',
            },
        ],
        []
    )

    const table = useReactTable({
        data: historyRecords ?? [],
        columns: columns,
        filterFns: { fuzzy: fuzzyFilter },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    })

    useEffect(() => {
        dispatch(fetchProductSchedulesHistory(guid, 'active'))

        const interval = setInterval(() => {
            dispatch(fetchProductSchedulesHistory(guid, 'active'))
        }, 30000)

        return () => clearInterval(interval)
    }, [])

    if (isLoading)
        return (
            <div className="vh-full d-flex justify-content-center">
                <Loader />
            </div>
        )

    if (error)
        return (
            <div className={`px-4 py-3 fs-5 ${styles.errorMsg}`}>
                <FormattedMessage id={error} />
            </div>
        )

    return !!historyRecords?.length ? (
        <div className="d-flex flex-column">
            <table className="table-round">
                <thead className="thead-round">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <StatusHistoryHeaders
                            key={headerGroup.id}
                            headerGroup={headerGroup}
                        />
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <StatusHistoryRow
                            key={row.id}
                            row={row}
                        />
                    ))}
                </tbody>
            </table>
            {/*TODO: uncomment when status history is updated in real time*/
                // hasMore && (
                //     <LoadMoreBtn
                //         onClick={handleMore}
                //         className="float-end mb-4"
                //     />
                // )
            }
        </div>
    ) : (
        <NoData />
    )
}
