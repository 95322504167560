import { CoreSettingsBackend, ICoreSettings } from '../../../types/coreSettings'

export const convertEmptyValuesToNull = (
    data: ICoreSettings | CoreSettingsBackend
) => {
    if (!data || typeof data !== 'object') {
        return data
    }

    Object.entries(data).forEach(([key, value]) => {
        if (value === '') {
            // @ts-ignore
            data[key] = null
        }
    })
}