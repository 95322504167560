import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar'
import { RightBarActionType } from '../../../types/rightbar'
import React from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { ScheduleBar } from '../../ScheduleBar/ScheduleBar'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectOneSystemAction } from '../../../store/selectors/systemActionsSelectors'
import { withTooltip } from 't4b-core-frontend'

interface ScheduleBtnProps {
    guid: string
    isDisabled?: boolean
}

export const ScheduleBtn = (props: ScheduleBtnProps) => {
    const { guid, isDisabled } = props
    const dispatch = useAppDispatch()

    const hasGetJobSchedule = useTypedSelector(
        selectOneSystemAction(guid, 'get_job_schedule')
    )

    const disabled = isDisabled || !hasGetJobSchedule

    const handleShowScheduler = () => {
        dispatch({
            type: RightBarActionType.SCHEDULE_BAR,
            payload: {
                rightBarItem: <ScheduleBar guid={guid} />,
            },
        })
    }

    const btn = (
        <button
            className="rounded-blue-btn"
            onClick={handleShowScheduler}
            disabled={disabled}
        >
            <FontAwesomeIcon
                icon={faCalendar}
                color="white"
            />
        </button>
    )

    return !disabled ? withTooltip(btn, 'Launch schedule', 'scheduleBtn') : btn
}
