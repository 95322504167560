import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectProductJobScheduleByGuid } from '../../../store/selectors/jobSchedulesSelectors'
import React from 'react'
import {
    fetchProductJobSchedule,
    updateProductJobSchedule,
} from '../../../store/actions/schedulesActions'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'

interface ScheduleSaveResetProps {
    guid: string
    isInvalid: boolean
}

export const ScheduleSaveReset = (props: ScheduleSaveResetProps) => {
    const { guid, isInvalid } = props
    const dispatch = useAppDispatch()
    const productSchedules = useTypedSelector(
        selectProductJobScheduleByGuid(guid)
    )

    const handleSave = () =>
        dispatch(updateProductJobSchedule(guid, productSchedules))

    const reset = () => dispatch(fetchProductJobSchedule(guid))

    const handleReset = () =>
        dispatch(
            showModal(ModalTypes.MAIN_MODAL, {
                body: `Reset all changes ?`,
                onSubmit: reset,
            })
        )

    return (
        <div className="d-flex gap-2 mb-4">
            <button
                className="app-btn-main app-btn-apply"
                onClick={handleReset}
            >
                Reset
            </button>
            <button
                className="app-btn-main app-btn-reset"
                onClick={handleSave}
                disabled={isInvalid}
            >
                Save
            </button>
        </div>
    )
}
