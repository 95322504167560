import { Dispatch } from 'redux'
import axios from 'axios'
import { apiVersion } from '../../versions'
import { getHeaders } from './http'
import { ProductsUtcOffsetActions } from '../../types/productsUtcOffset'
import { alertErrorMessage } from '../../utils/Errors'

export const fetchUtcOffset = (productId: string): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data: offset } = await axios.get<number>(
                `/api/${apiVersion}/products/${productId}/utc_offset`,
                {
                    headers: getHeaders(),
                }
            )

            if (typeof offset !== 'number') {
                const error = new Error('Received utc offset in wrong format')
                console.error(error.message)
                return
            }

            dispatch({
                type: ProductsUtcOffsetActions.SET_PRODUCT_UTC_OFFSET,
                payload: { guid: productId, data: offset },
            })
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}
