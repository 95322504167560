import React from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { IUserBackend, User, UserRoles } from '../../../../entity/User'
import { buildControlsExt, selectInput } from '../../../inputs/controls'
import { UserRoleDescription } from '../../../UserRoleDescription'
import { getOptions } from '../helpers'

interface IInformation {
    inputState: any
    setInputState: any
    isReadOnly: boolean
    administeredUser: IUserBackend
    authUser: User
    className?: string
}

export const Information = ({
    inputState,
    setInputState,
    authUser,
    administeredUser,
    isReadOnly,
    className,
}: IInformation) => {
    const disabledOptions = [UserRoles.NOT_STANDARD_ROLE]
    const options: UserRoles[] = getOptions(
        User.getRole(administeredUser),
        authUser,
        isReadOnly
    )

    const selectUserType = buildControlsExt(
        [
            selectInput({
                name: 'role',
                label: 'Role',
                isColumn: true,
                className: 'mb-2 label-sm',
                options,
                disabledOptions,
                isDisabled: isReadOnly || !options.length,
                tooltipPosition: 'right-start',
                tooltipText: <UserRoleDescription authUser={authUser} />,
            }),
        ],
        inputState,
        setInputState,
        ''
    )

    return (
        <Card.Header className={className}>
            <p className="navi-blue mb-4 h5">
                <FormattedMessage id="user.userData.information" />
            </p>
            <div className="d-flex">
                <p
                    style={{ width: '28%' }}
                    className="label-main mt-1 text-capital"
                >
                    <FormattedMessage id="user.userData.login" />
                </p>
                <p
                    style={{ width: '70%' }}
                    className="label-main mt-1 ps-2"
                >
                    {administeredUser.login}
                </p>
            </div>
            <div id="example-collapse-text">{selectUserType}</div>
        </Card.Header>
    )
}
