import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { transformDate } from '../../models/DateTime/utils'
import { TimeFormats } from '../../models/DateTime/types'
import AppButton from '../../buttons/AppButton/AppButton'
import { FormattedMessage } from 'react-intl'
import * as React from 'react'

interface TimePickerProps {
    time: Date | null | undefined
    index: number
    onTimeChange: (index: number, time: string) => void
    onTimeDelete: (index: number) => void
    isDeleteDisabled?: boolean
    className?: string
}

export const TimePicker = (props: TimePickerProps) => {
    const {
        time,
        index,
        onTimeChange,
        onTimeDelete,
        isDeleteDisabled= false,
        className,
    } = props

    const handleTimeChange = (time: Date) => {
        const timeFormatted =
            transformDate(
                TimeFormats.HOURS_MINUTES_SECONDS,
                time,
                TimeFormats.HOURS_MINUTES_SECONDS
            ) ?? ''
        onTimeChange(index, timeFormatted)
    }

    const handleDelete = () => onTimeDelete(index)
    const isInvalid = !time

    return (
        <div className="p-relative">
            <>
            <DatePicker
                className={`${className} ${isInvalid && 'form-control is-invalid'}`}
                selected={time}
                onChange={handleTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                timeFormat="HH:mm:ss"
                dateFormat="HH:mm:ss"
                placeholderText="Time *"
                showIcon
                toggleCalendarOnIconClick
                icon={
                    <FontAwesomeIcon
                        icon={faClock}
                        color="grey"
                    />
                }
                enableTabLoop={false}
            />
                {isInvalid && (
                    <p
                        style={{
                            position: 'absolute',
                            color: '#dc3545',
                            marginBottom: '0',
                            fontSize: '0.7rem',
                        }}
                    >
                        <FormattedMessage id="errors.validation.fieldRequired" />
                    </p>
                )}
            </>
            {!isDeleteDisabled && (
                <AppButton
                    variant="close"
                    tooltip="Delete time"
                    onClick={handleDelete}
                    className="position-absolute bottom-0 right-0 mb-1 me-1"
                />
            )}
        </div>
    )
}
