import { useDispatch, useSelector } from 'react-redux'
import { RightBarActionType } from '../../types/rightbar'
import { AppRightBar } from './AppRightBar'

export const RightBar = () => {
    const dispatch = useDispatch()
    const {
        rightBarHidden,
        rightBarItem,
        fullScreen,
        actionsBar,
        scheduleBar,
    } = useSelector((state: any) => state.rightBar)

    const handleClick = (): void => {
        dispatch({ type: RightBarActionType.HIDE_RIGHT_BAR })
    }

    return (
        <AppRightBar
            className={`${fullScreen && 'app-rightbar-full-screen'} ${
                actionsBar && 'app-actions-bar'
            } ${scheduleBar && 'app-schedule-bar'}`}
            hidden={rightBarHidden}
            items={rightBarItem}
            onClick={handleClick}
            nonResizable={!!scheduleBar}
        />
    )
}
