import React, { FC, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import mainLogo from './assets/images/logo.png'
import MESSAGES_EN from './i18n/en.json'
import { fetchSignIn } from './store/actions/authentication'
import { AuthActionType } from './types/auth'
import SideBar from './components/SideBar/SideBar'
import GUIConstructor from './components/GUIConstructor/GUIConstructor'
import { useTypedSelector } from './hooks/useTypedSelector'
import useUnload from './hooks/useUnLoad'
import { IRoute, routes, RoutesPaths } from './router'
import LicenceInvalid from './components/LicenceInvalid'
import { IProduct } from './types/product'
import { LicenceActionType } from './store/reducer/licenceReducer'
import { hideModal } from './store/actions/modalActions'
import { LogoutButton } from './components/buttons/LogoutButton/LogoutButton'
import { AppLoginPage, AppNavbar, PrivateRoute } from 't4b-core-frontend'
import { ILogo } from 't4b-core-frontend/lib/entity/logo'
import { flattenMessages } from './utils/flattenMessages'
import { Modals } from './components/modals/Modals'
import { signOut } from './utils/authentication'
import { RightBar } from './components/rightbar/RightBar'
import { SideBarActionType } from './store/reducer/sideBarReducer'
import { NotificationsBtn } from './components/buttons/NotificationsBtn/NotificationsBtn'
import { CustomerPortalBtn } from './components/buttons/CustomerPortalBtn/CustomerPortalBtn'

const appLogo: ILogo = {
    src: mainLogo,
    height: 48,
    width: 215,
    className: '',
    alt: 'logo',
    href: '/',
}

const APP_LANGUAGES: any = {
    en: 'English',
}

export const messages: any = {
    en: MESSAGES_EN,
}

export function getLanguage(): string {
    return localStorage.getItem('lang') || 'en'
}

export const UserContext = React.createContext<any>(null)

export const App: FC = () => {
    const [currentLang, setCurrentLang] = useState(getLanguage())
    const sidebarHidden = useTypedSelector((store) => store.sideBar)
    const dispatch = useDispatch()
    const { auth: isAuthenticated, licence } = useSelector(
        (state: any) => state
    )
    const { products } = useTypedSelector((state) => state.products)
    const { rightBarHidden } = useTypedSelector((state) => state.rightBar)
    const isRenderCount = process.env.REACT_APP_RENDER_COUNT === 'yes'
    if (isRenderCount) {
        console.count('App render')
    }

    useUnload((e: any) => {
        e.preventDefault()
        e.returnValue = ''
    })

    const handleLangChange = (lang: string): void => {
        localStorage.setItem('lang', lang)
        setCurrentLang(lang)
    }

    const handleSidebarClick = () => {
        dispatch({
            type: SideBarActionType.TOGGLE_SIDEBAR,
            payload: !sidebarHidden,
        })
    }

    const onSignIn = (login: string, password: string): void => {
        fetchSignIn(login, password, () => {
            dispatch({ type: AuthActionType.SIGN_IN })
        })
    }

    const renderAppLoginPage = (): JSX.Element =>
        isAuthenticated ? (
            <Redirect
                exact
                to={RoutesPaths.HOME}
            />
        ) : (
            <div className="ms-auto me-auto app-login-container">
                <AppLoginPage
                    canBeRestored={false}
                    canBeRegistered={false}
                    signInFunc={onSignIn}
                />
            </div>
        )

    axios.interceptors.response.use(
        (config) => {
            return config
        },
        async (err) => {
            if (err.config?.url === RoutesPaths.REFRESH_TOKEN) {
                signOut()
                dispatch({ type: AuthActionType.SIGN_OUT })
                dispatch(hideModal())
            }
            if (err.response?.status === 503) {
                signOut()
                dispatch({ type: AuthActionType.SIGN_OUT })
                dispatch({ type: LicenceActionType.LICENCE_INVALID })
            }
            throw err
        }
    )

    return (
        <IntlProvider
            locale={currentLang}
            messages={flattenMessages(messages[currentLang])}
        >
            <UserContext.Provider value={{ isAuthenticated }}>
                <div>
                    <AppNavbar
                        logo={appLogo}
                        i18nLangs={APP_LANGUAGES}
                        currentLangKey={currentLang}
                        onLangChange={handleLangChange}
                        isAuthenticated={isAuthenticated && licence}
                        onSidebarHandlerClick={handleSidebarClick}
                        sidebarHidden={sidebarHidden}
                        navbarItemsRight={[
                            <CustomerPortalBtn key="1" />,
                            <NotificationsBtn key="2" />,
                        ]}
                        exitButton={<LogoutButton />}
                    />
                </div>
                {!licence ? (
                    <LicenceInvalid />
                ) : (
                    <div className="d-flex vh-full-header app">
                        {!isAuthenticated && (
                            <Redirect to={{ pathname: '/login' }} />
                        )}
                        {isAuthenticated && (
                            <SideBar sidebarHidden={sidebarHidden} />
                        )}

                        <Switch>
                            <Route
                                path="/login"
                                component={renderAppLoginPage}
                            />
                            <PrivateRoute
                                exact
                                path="/"
                                isAuthenticated={false}
                                component={renderAppLoginPage}
                            />

                            {routes.map((page: IRoute) => (
                                <PrivateRoute
                                    exact={page.exact}
                                    path={page.path}
                                    isAuthenticated={isAuthenticated}
                                    component={page.component}
                                    key={page.path}
                                />
                            ))}

                            {products.map((product: IProduct) => {
                                return (
                                    <PrivateRoute
                                        path={`/${product.guid}`}
                                        isAuthenticated={isAuthenticated}
                                        key={product.guid}
                                        component={
                                            <GUIConstructor
                                                path={`/${product.guid}`}
                                                guid={product.guid}
                                                appName={product.name}
                                                product={product}
                                            />
                                        }
                                    />
                                )
                            })}
                        </Switch>
                        {!rightBarHidden && <RightBar />}
                        <div id="rule-modal"></div>
                    </div>
                )}
                <ToastContainer
                    pauseOnHover
                    closeOnClick={false}
                    position="bottom-right"
                />
                <Modals />
            </UserContext.Provider>
        </IntlProvider>
    )
}
