import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import {
    IUserFrontend,
    UserPermissions,
    UserRoles,
} from '../../../../entity/User'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { showModal } from '../../../../store/actions/modalActions'
import { deleteUser, modifyUser } from '../../../../store/actions/usersActions'
import { ModalTypes } from '../../../../types/modals'
import { createUserPermissions } from '../../../../utils/userRights'

interface IFooter {
    isCurrentUser: boolean
    inputState: any
    setInputState: any
    setTouched: any
    touched: any
    index: number
    isReadOnly: boolean
    userLocal: any
    validationResult: any
    user: IUserFrontend
}

export const Footer: FC<IFooter> = ({
    isCurrentUser,
    inputState,
    setInputState,
    setTouched,
    validationResult,
    touched,
    index,
    isReadOnly,
    userLocal,
    user,
}) => {
    const dispatch = useAppDispatch()

    const getTouched = (): boolean => {
        return JSON.stringify(inputState) === JSON.stringify(userLocal)
    }

    const handleReset = (): void => {
        setInputState(userLocal)
        setTouched({})
    }

    const handleOnDelete = (): void => {
        dispatch(
            showModal(ModalTypes.MAIN_MODAL, {
                body: `Delete user ${user.login}`,
                onSubmit: () => dispatch(deleteUser(user)),
            })
        )
    }

    const isPasswordChanged = touched.newPassword || touched.confirmPassword

    const IsAbleToConfirm: boolean =
        isPasswordChanged && !!Object.keys(validationResult).length

    const isTouched = getTouched()

    const handleOnApply = (): void => {
        if (!isCurrentUser) {
            const user: any =
                inputState.role === UserRoles.ADMINISTRATOR ||
                inputState.role === UserRoles.OWNER
                    ? {
                          login: inputState.login,
                          permissions: createUserPermissions(inputState.role),
                          visibility: {
                              ...inputState.visibility,
                              all_products: true,
                          },
                      }
                    : {
                          login: inputState.login,
                          permissions: createUserPermissions(inputState.role),
                          visibility: inputState.visibility,
                      }
            if (inputState.newPassword) {
                user.password = inputState.newPassword
            }
            setInputState((prev: typeof inputState) => ({
                ...prev,
                newPassword: '',
                confirmPassword: '',
            }))
            setTouched({})
            dispatch(modifyUser(user, index))
        } else {
            const user = {
                login: inputState.login,
                permissions: inputState.user_management
                    ? [
                          UserPermissions.CONFIGURATION_MANAGEMENT,
                          UserPermissions.USER_MANAGEMENT,
                      ]
                    : [UserPermissions.CONFIGURATION_MANAGEMENT],
            }
            dispatch(modifyUser(user, index))
        }
    }
    return (
        <Card.Footer className="d-flex">
            <button
                type="button"
                onClick={handleOnApply}
                disabled={isReadOnly || isTouched || IsAbleToConfirm}
                className="app-btn-apply app-btn-main app-btn-large"
            >
                <FormattedMessage id="buttons.apply" />
            </button>
            <button
                type="button"
                disabled={isReadOnly || isTouched}
                onClick={handleReset}
                className="app-btn-cancel app-btn-main app-btn-large ms-2"
            >
                <FormattedMessage id="buttons.cancel" />
            </button>
            {!isCurrentUser && (
                <button
                    type="button"
                    onClick={handleOnDelete}
                    className="app-btn-delete app-btn-main app-btn-large ms-2"
                    disabled={isReadOnly}
                >
                    <FormattedMessage id="user.userData.deleteUser" />
                </button>
            )}
        </Card.Footer>
    )
}
