import { flexRender, HeaderGroup } from '@tanstack/react-table'
import React from 'react'

interface StatusHistoryColumnHeaderProps {
    headerGroup: HeaderGroup<unknown>
}

export const StatusHistoryHeaders = (props: StatusHistoryColumnHeaderProps) => {
    const { headerGroup } = props

    return (
        <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
                <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="th-round"
                >
                    {header.isPlaceholder ? null : (
                        <div>
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                        </div>
                    )}
                </th>
            ))}
        </tr>
    )
}
