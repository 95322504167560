import { Dispatch } from 'redux'
import axios from 'axios'
import { apiVersion } from '../../versions'
import {
    CoreSettingsActionType,
    CoreSettingsBackend,
    ICoreSettings,
} from '../../types/coreSettings'
import { getHeaders } from './http'
import { alertErrorMessage, throwSuccessMessage } from '../../utils/Errors'
import { AppDispatch } from '../../types/store'
import { RightBarActionType } from '../../types/rightbar'
import { idleAndStandByMapper } from '../../components/CoreSettings/utils/IdleAndStandByMapper'
import { convertEmptyValuesToNull } from '../../components/CoreSettings/utils/convertEmptyValuesToNull'

// ============= actions ============ //

export const modifyCoreSettings =
    (payload: { guid: string; data: ICoreSettings }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: CoreSettingsActionType.MODIFY_CORE_SETTINGS,
            payload
        })
    }


// ========= action creators ======= //
export const fetchProductCoreSettings = (productId: string): any => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: CoreSettingsActionType.GET_CORE_SETTINGS_REQUEST,
        })
        try {
            const { data } = await axios.get<CoreSettingsBackend>(
                `/api/${apiVersion}/products/${productId}/core_settings/general`,
                {
                    headers: getHeaders(),
                }
            )

            let mappedData = idleAndStandByMapper(data)
            
            dispatch({
                type: CoreSettingsActionType.GET_CORE_SETTINGS_SUCCESS,
                payload: { guid: productId, data: mappedData },
            })
        } catch (e: any) {
            dispatch({
                type: CoreSettingsActionType.GET_CORE_SETTINGS_ERROR,
            })
            alertErrorMessage(e)
        }
    }
}

export const updateProductCoreSettings = (
    productId: string,
    coreSettings: ICoreSettings
): any => {
    return async (dispatch: AppDispatch) => {
        try {
            const data = idleAndStandByMapper(coreSettings)
            convertEmptyValuesToNull(data)

            await axios.put(
                `/api/${apiVersion}/products/${productId}/core_settings/general`,
                data,
                {
                    headers: getHeaders(),
                }
            )
            throwSuccessMessage('General settings successfully updated')
            dispatch(fetchProductCoreSettings(productId))
            dispatch({ type: RightBarActionType.HIDE_RIGHT_BAR})
        } catch (e: any) {
            alertErrorMessage(e)
        }
    }
}
