import { IErrMsg } from '../../components/inputs/types'

export const controllerErrors = {
    adminController(state: any): IErrMsg | undefined {
        if (state?.login?.length === 0) {
            return { message: 'errors.validation.fieldRequired' }
        }
        if (!state?.password?.length) {
            return { message: 'errors.validation.fieldRequired' }
        }
        if (state?.password?.length < 5) {
            return {
                message: 'errors.validation.minSize',
                values: { minSize: '5' },
            }
        }
    },
    adminControllerOldPassword(state: any): IErrMsg | undefined {
        if (!state?.oldPassword?.length) {
            return { message: 'errors.validation.fieldRequired' }
        }
        if (state?.oldPassword?.length < 5) {
            return {
                message: 'errors.validation.minSize',
                values: { minSize: '5' },
            }
        }
    },
    adminControllerNewPassword(state: any): IErrMsg | undefined {
        if (!state?.newPassword) {
            return { message: 'errors.validation.fieldRequired' }
        }
        if (state?.newPassword?.length < 5) {
            return {
                message: 'errors.validation.minSize',
                values: { minSize: '5' },
            }
        }
        if (state?.newPassword === state?.oldPassword) {
            return { message: 'errors.validation.theSameValues' }
        }
    },
    adminControllerRepeatPassword(state: any): IErrMsg | undefined {
        if (!state?.confirmPassword) {
            return { message: 'errors.validation.fieldRequired' }
        }
        if (state?.confirmPassword?.length < 5) {
            return {
                message: 'errors.validation.minSize',
                values: { minSize: '5' },
            }
        }
        if (state?.confirmPassword !== state?.newPassword) {
            return { message: 'errors.validation.passwordsNotMatch' }
        }
    },
}
