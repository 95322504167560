import { User } from '../entity/User'
import { inputTypes } from '../validators/inputValidators/types'

export const getIsViewOnly = (
    restrictedAccess: boolean | string | undefined
) => {
    const authUser = new User()
    const isRestrictedAccess =
        restrictedAccess === true || restrictedAccess === 'true'

    return authUser.isViewer() || (isRestrictedAccess && authUser.isWl())
}

export const getViewOnlyValue = (value: string, type?: string) => {
    if (type === inputTypes.SECRET && value) {
        return '******'
    }
    return String(value)
}
