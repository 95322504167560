import { useTypedSelector } from '../../hooks/useTypedSelector'
import { selectOneProductUtcOffset } from '../../store/selectors/productsUtcOffsetSelectors'
import { fetchUtcOffset } from '../../store/actions/productsUtcOffcetActions'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { memo, useEffect, useState } from 'react'
import { getProductTime } from '../../utils/getProductTime'
import { useInitialEffect } from '../../hooks/useInitialEffect'

interface ProductTimeProps {
    guid: string
    className?: string
}

export const ProductTime = memo(function ProductTime(props: ProductTimeProps) {
    const { guid, className } = props
    const dispatch = useAppDispatch()

    const [datetime, setDatetime] = useState('')
    const productUtcOffset = useTypedSelector(selectOneProductUtcOffset(guid))

    useInitialEffect(() => {
        dispatch(fetchUtcOffset(guid))
    })

    useEffect(() => {
        const timeInterval = setInterval(() => {
            const productTime = getProductTime(productUtcOffset)
            setDatetime(productTime ?? '')
        }, 1000)

        return () => {
            clearInterval(timeInterval)
        }
    }, [productUtcOffset])

    return <div className={className}>{datetime}</div>
})