import { flexRender, Row } from '@tanstack/react-table'
import React from 'react'

interface StatusHistoryRowProps {
    row: Row<unknown>
}

export const StatusHistoryRow = (props: StatusHistoryRowProps) => {
    const { row } = props
    return (
        <tr
            key={row.id}
            className='tr-round'
        >
            {row.getVisibleCells().map((cell) => (
                <td
                    key={cell.id}
                    className='tcell-round'
                >
                    <div className='tcell-round-content'>
                        {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                        )}
                    </div>
                </td>
            ))}
        </tr>

    )
}