import React, { FC, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import ErrMessageTooltip from '../../Errors/ErrMessageTooltip'
import { FormattedMessage } from 'react-intl'
import PromptBtn from '../../buttons/PromptBtn/PromptBtn'
import { IDoubleInput } from '../types'
import { isValidDouble } from './utils/isValidDouble'
import { startsWithConsecutiveZeros } from './utils/startsWithConsecutiveZeros'
import { isEmptyString } from '../../../utils/isEmptyString'

const DoubleInput: FC<IDoubleInput> = ({
    setState,
    setTouched,
    className,
    state,
    touched,
    errors,
    errMsg,
    name,
    label,
    precision,
    placeholder,
    isDisabled,
    tooltipText,
    isColumn,
    isLabelHidden,
    isRequired,
    isFeedbackFixed,
    onEnter,
    onBlur,
}) => {
    const [showNumOnlyTooltip, setShowNumOnlyTooltip] = useState(false)
    const target = useRef(null)
    const isInvalid = touched && touched[name] && errors && errors[name]

    const handleBlur = () => {
        setShowNumOnlyTooltip(false)
        if (typeof onBlur === 'function') return onBlur()

        setTouched && setTouched({ ...touched, [name]: true })
        if (state[name] === '') {
            setState({ ...state, [name]: null })
        }
        if (state[name] !== '' || state[name] !== null) {
            const val = Number.isInteger(Number(state[name]))
                ? state[name]
                : String(state[name]).replaceAll(',', '')

            setState({ ...state, [name]: val })
        }
    }

    const handleChange = (event: any): void => {
        const newValue = event.target.value
        const prevValue = state[name]

        if (isValidDouble(newValue)) {
            setShowNumOnlyTooltip(false)
            setTouched && setTouched({ ...touched, [name]: true })

            if (startsWithConsecutiveZeros(newValue, prevValue)) {
                return
            }
            if (isEmptyString(newValue)) {
                setState({ ...state, [name]: null })
                return
            }
            setState({ ...state, [name]: newValue })
        } else {
            setShowNumOnlyTooltip(true)
        }
    }

    const handleOnKeyDown = (evt: any) => {
        if (evt.key === 'Enter' && typeof onEnter === 'function') {
            onEnter()
        }
    }

    useEffect(() => {
        if (state?.[name] === null && isRequired) {
            setState({ ...state, [name]: '' })
        }
    }, [state?.[name]]) // eslint-disable-line

    return (
        <Form.Group
            style={{ maxWidth: '600px' }}
            className={`${className} ${
                isInvalid && !isFeedbackFixed && 'mb-4'
            } d-flex flex-1 mt-1 mb-1 justify-content-between align-items-baseline`}
        >
            {label && !isLabelHidden ? (
                <Form.Label
                    style={{ maxWidth: isColumn ? '30%' : 'auto' }}
                    className="label-main mt-1 text-capital pe-2"
                >
                    <span className="label-main-wrapper p-relative">
                        {label}:{' '}
                        {isRequired && (
                            <span className="app-color-text label-required-mark">
                                *
                            </span>
                        )}
                    </span>
                </Form.Label>
            ) : null}
            <span
                style={{ width: '70%' }}
                className={`d-flex ${!isColumn ? 'flex-1' : ''} p-relative`}
            >
                <span className="d-flex flex-column w-100">
                    <Form.Control
                        className={`input-main ${
                            tooltipText && 'input-padding'
                        }`}
                        type="string"
                        placeholder={placeholder}
                        value={state?.[name] ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!!isDisabled}
                        isInvalid={
                            touched && touched[name] && errors && errors[name]
                        }
                        ref={target}
                        name={name}
                        onKeyDown={handleOnKeyDown}
                    />
                    <Form.Control.Feedback
                        className="input-feedback"
                        type="invalid"
                    >
                        <FormattedMessage
                            id={errMsg?.message || 'field-validation-error'}
                            values={errMsg?.values}
                            tagName="span"
                        />
                    </Form.Control.Feedback>
                </span>
                {tooltipText && (
                    <PromptBtn
                        className="mt-1 input-tooltip"
                        prompt={tooltipText ?? ''}
                    />
                )}
            </span>
            <ErrMessageTooltip
                message="num-only-toast"
                error={showNumOnlyTooltip}
                target={target}
            />
        </Form.Group>
    )
}

export default DoubleInput
