import styles from './index.module.scss'
import { useState } from 'react'
import { MainInfo } from './MainInfo'
import { Facts } from './Facts/Facts'
import { News } from './News/News'
import { useInitialEffect } from '../../hooks/useInitialEffect'
import { getFacts, getNews } from '../../store/actions/homePageActions'
import { groupHomePageItems } from '../../utils/groupHomePageItems'
import { alertDetailedErrorMessage } from '../../utils/Errors'

export type HomePageItem = {
    id: string
    title: string
    description: string
    link: string
    linkName: string
    data: string // base64
}

export const HomePage = () => {
    const [facts, setFacts] = useState<Array<HomePageItem[]>>([])
    const [news, setNews] = useState<Array<HomePageItem[]>>([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useInitialEffect(() => {
        setIsLoading(true)
        if (isError) setIsError(false)

        Promise.all([getFacts(window.origin), getNews(window.origin)])
            .then(([factItems, newsItems]) => {
                if (factItems && factItems.length) {
                    setFacts(groupHomePageItems(factItems, 2))
                }
                if (newsItems && newsItems.length) {
                    setNews(groupHomePageItems(newsItems, 4))
                }
                setIsLoading(false)
            })
            .catch((_error) => {
                setIsError(true)
                setIsLoading(false)
                alertDetailedErrorMessage('Home page content is not available')
            })
    })

    return (
        <div className={styles.pageContainer}>
            <div className={`${styles.mainContainer} flex-1 flex-column gap-4`}>
                <div className={`${styles.gridWrapper}`}>
                    <MainInfo />
                    <Facts
                        isLoading={isLoading}
                        isError={isError}
                        facts={facts}
                    />
                </div>
                <News
                    isLoading={isLoading}
                    isError={isError}
                    news={news}
                />
            </div>
        </div>
    )
}
