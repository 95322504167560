import { ModelTypes } from '../components/models/types'
import _cloneDeep from 'lodash/cloneDeep'

export const getEmptyData = (schema: any) => {
    switch (schema?.type) {
        case ModelTypes.BLOCK:
            if (schema.hasOwnProperty('default')) {
                return _cloneDeep(schema.default)
            }
            return {}

        case ModelTypes.BLOCK_DICTIONARY:
            if (schema.hasOwnProperty('default')) {
                return _cloneDeep(schema.default ?? {})
            }
            return {}

        case ModelTypes.BLOCK_ARRAY:
            if (schema.hasOwnProperty('default')) {
                return _cloneDeep(schema.default ?? [])
            }
            return []

        case ModelTypes.FLAG:
            return schema.default || false

        case ModelTypes.BOOL:
            return schema.default || false

        case ModelTypes.ENUM:
            return schema?.default || Object.keys(schema?.options || {})[0] || 0

        case ModelTypes.DOUBLE:
            return schema.default || null

        case ModelTypes.INTEGER:
            return schema.default || null

        case ModelTypes.DURATION:
            return schema.default || null

        case ModelTypes.NUMBER:
            return schema.default || null

        case ModelTypes.VERSION:
            return schema.default || null

        case ModelTypes.SECRET:
            return ''

        default:
            return null
    }
}
