import { ScheduleItem } from '../ScheduleItem/ScheduleItem'
import React, { useEffect } from 'react'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import Loader from '../../Loader/Loader'
import {
    selectJobSchedulesState,
    selectProductJobScheduleByGuid,
} from '../../../store/selectors/jobSchedulesSelectors'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import {
    addSchedule,
    fetchProductJobSchedule,
} from '../../../store/actions/schedulesActions'
import styles from './ScheduleList.module.scss'
import { FormattedMessage } from 'react-intl'
import AppButton from '../../buttons/AppButton/AppButton'
import { Schedule } from '../lib/Schedule'
import { ScheduleSaveReset } from '../ScheduleSaveReset/ScheduleSaveReset'

interface ScheduleListProps {
    guid: string
}

export const ScheduleList = (props: ScheduleListProps) => {
    const { guid } = props
    const dispatch = useAppDispatch()
    const { isLoading, error } = useTypedSelector(selectJobSchedulesState)
    const productSchedules = useTypedSelector(
        selectProductJobScheduleByGuid(guid)
    )
    const schedules = productSchedules || []

    const addScheduleItem = () => {
        dispatch(
            addSchedule({
                guid,
                schedules: [...schedules, new Schedule()],
            })
        )
    }

    useEffect(() => {
        if (!productSchedules) {
            dispatch(fetchProductJobSchedule(guid))
        }
    }, [])

    const scheduleList = schedules.map((schedule, index) => (
        <ScheduleItem
            key={index}
            guid={guid}
            schedule={schedule}
            index={index}
        />
    ))

    const invalidFieldsCount = schedules.reduce((acc, schedule) => {
        const invalidTimeFields = schedule.start_time?.filter((time) => !time)
        if (invalidTimeFields.length) {
            acc += invalidTimeFields.length
        }
        const daysOfWeekValues = Object.values(schedule.days_of_week)
        const isAllDaysFalse = daysOfWeekValues.every((day) => !day)
        if (isAllDaysFalse) {
            acc += 1
        }
        return acc
    }, 0)

    const listContent = schedules.length ? (
        scheduleList
    ) : (
        <p className="m-auto text-center">
            No schedule is set for this product. Press the button below to add
            one.
        </p>
    )

    if (isLoading)
        return (
            <div className="vh-full d-flex justify-content-center">
                <Loader />
            </div>
        )

    if (error)
        return (
            <div className={`px-4 py-3 fs-5 ${styles.errorMsg}`}>
                <FormattedMessage id={error} />
            </div>
        )

    return (
        <div className="d-flex flex-column gap-3">
            {listContent}
            <AppButton
                variant="add"
                onClick={addScheduleItem}
                tooltip="Add item"
                className="my-2 me-3 hover"
            />
            <ScheduleSaveReset
                guid={guid}
                isInvalid={!!invalidFieldsCount}
            />
        </div>
    )
}
