import {
    ConfigurationsActionType,
    IConfigState,
} from '../../types/configurations'
import { validationActions } from '../actions/modelsActions/validationActions'
import { blockActions } from '../actions/modelsActions/blockActions'
import { blockArrayActions } from '../actions/modelsActions/blockArrayActions'
import { blockDictionaryActions } from '../actions/modelsActions/blockDictionaryActions'
import { initEmptyDataAction } from '../actions/modelsActions/initEmptyData'
import { inputActions } from '../actions/modelsActions/inputActions'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'

const initialState: IConfigState = {
    productsConfigurations: {},
    productsConfigurationsInterim: {},
    productsConfigurationsDefault: {},
    unsaved: {},
    loading: false,
    fetchingError: false,
    invalidFields: {},
    rulesUpdating: {},
    isChecked: {},
    isTesting: false,
}

export const configurationsReducer = (
  state = initialState,
  action: { type: string; payload: any }
): IConfigState => {
  const path = action?.payload?.path

  switch (action.type) {
    case ConfigurationsActionType.DISABLE_BLOCK: {
      blockActions.disableBlock(state, path, action.payload.name)

      const [guid, , tabName] = path
      const errorKeyName = action.payload.errorKeyName
      validationActions.cleanBlockInvalidFields(guid, tabName, errorKeyName, state.invalidFields)

      return { ...state }
    }

    case ConfigurationsActionType.ENABLE_BLOCK:
      blockActions.enableBlock(
        state,
        path,
        action.payload.name,
        action.payload.data
      )
      return { ...state }

    case ConfigurationsActionType.FETCH_CONFIGURATION:
      return { ...state, loading: true, fetchingError: false }

    case ConfigurationsActionType.FETCH_CONFIGURATION_ERROR:
      return { ...state, loading: false, fetchingError: true }

    case ConfigurationsActionType.FETCH_CONFIGURATION_SUCCESS: {
      if (state.productsConfigurations[action.payload.guid] && !action.payload.forceUpdate) {
        return { ...state, loading: false }
      }
      const { guid, data: configuration } = action.payload
      const configCloneInterim = _cloneDeep(configuration)
      const configCloneDefault = _cloneDeep(configuration)

      return {
        ...state,
        productsConfigurations: {
          ...state.productsConfigurations,
          [guid]: configuration,
        },
        productsConfigurationsInterim: {
          ...state.productsConfigurationsInterim,
          [guid]: configCloneInterim,
        },
        productsConfigurationsDefault: {
          ...state.productsConfigurationsDefault,
          [guid]: configCloneDefault,
        },
        invalidFields: {
          ...state.invalidFields,
          [guid]: {},
        },
        loading: false,
        fetchingError: false,
      }
    }

    case ConfigurationsActionType.CHECK_INVALID_TABS:
      return {
        ...state,
        isChecked: {
          ...state.isChecked,
          [action.payload.guid]: state.isChecked[action.payload.guid]
            ? state.isChecked[action.payload.guid] + action.payload.data
            : 1,
        },
      }

    case ConfigurationsActionType.CONFIGURATION_RESET:
      return { ...state, loading: true }

    case ConfigurationsActionType.CONFIGURATION_RESET_SUCCESS: {
      const { guid, data: configuration } = action.payload
      const configCloneInterim = _cloneDeep(configuration)
      const configCloneDefault = _cloneDeep(configuration)

      return {
        ...state,
        productsConfigurations: {
          ...state.productsConfigurations,
          [guid]: configuration,
        },
        productsConfigurationsInterim: {
          ...state.productsConfigurationsInterim,
          [guid]: configCloneInterim,
        },
        productsConfigurationsDefault: {
          ...state.productsConfigurationsDefault,
          [guid]: configCloneDefault,
        },
        invalidFields: {
          ...state.invalidFields,
          [guid]: {},
        },
        loading: false,
        isChecked: 0,
      }
    }

    case ConfigurationsActionType.APPLY_CONFIGURATION:
      return { ...state, loading: true }

    case ConfigurationsActionType.APPLY_CONFIGURATION_SUCCESS: {
      const { configuration, guid } = action.payload
      const configCloneInterim = _cloneDeep(configuration)
      const configCloneDefault = _cloneDeep(configuration)

      return {
        ...state,
        productsConfigurations: {
          ...state.productsConfigurations,
          [guid]: configuration,
        },
        productsConfigurationsInterim: {
          ...state.productsConfigurationsInterim,
          [guid]: configCloneInterim,
        },
        productsConfigurationsDefault: {
          ...state.productsConfigurationsDefault,
          [guid]: configCloneDefault,
        },
        loading: false,
      }
    }

    case ConfigurationsActionType.SET_INVALID_CONFIGURATION: {
      const { guid, tabName, errorKeyName } = action.payload

      return {
        ...state,
        invalidFields: {
          ...state.invalidFields,
          [guid]: {
            ...(state.invalidFields[guid]),
            [tabName]: {
              ...(state.invalidFields[guid]?.[tabName] ?? {}),
              [errorKeyName]: true,
            },
          },
        },
      }
    }

    case ConfigurationsActionType.SET_VALID_CONFIGURATION: {
      const { guid, tabName, errorKeyName } = action.payload
      validationActions.cleanInvalidField(guid, tabName, errorKeyName, state.invalidFields)

      return {
        ...state,
        invalidFields: {
          ...state.invalidFields,
          [guid]: { ...state.invalidFields[guid] },
        },
      }
    }

    case ConfigurationsActionType.BLOCK_DICTIONARY_ADD:
      blockDictionaryActions.addItem(
        state,
        path,
        action.payload.item,
        action.payload.data
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_DICTIONARY_NEW_KEY:
      blockDictionaryActions.newKey(
        state,
        path,
        action.payload.data,
        action.payload.oldKey
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_DICTIONARY_DELETE:
      blockDictionaryActions.deleteItem(
        state,
        path,
        action.payload.item,
        action.payload.data
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_DICTIONARY_EDIT_DICT_VALUE:
      blockDictionaryActions.editDictionaryValue(
        state,
        path,
        action.payload.key,
        action.payload.value
      )
      return { ...state }

    case ConfigurationsActionType.FLAG_MODIFY:
      inputActions.flagModify(
        state,
        path,
        action.payload.componentState,
        action.payload.data
      )
      return { ...state }

    case ConfigurationsActionType.INPUT_MODIFY:
      inputActions.inputModify(
        state,
        path,
        action.payload.componentState,
        action.payload.data,
        action.payload.name,
        action.payload?.type,
        action.payload?.isEmptyAsNull
      )
      return { ...state }

    case ConfigurationsActionType.MULTI_SELECT_MODIFY:
      inputActions.multiSelectModify(
        state,
        path,
        action.payload.componentState,
        action.payload.name,
        action.payload?.isNullable,
        action.payload?.isEmptyAsNull
      )
      return { ...state }

    case ConfigurationsActionType.DATE_TIME_MODIFY:
      inputActions.dateTimeModify(
        state,
        path,
        action.payload.componentState,
        action.payload.data,
        action.payload.name
      )
      return { ...state }

    case ConfigurationsActionType.ENUM_MODIFY:
      inputActions.enumModify(
        state,
        path,
        action.payload.componentState,
        action.payload.data
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_ARRAY_DND:
      blockArrayActions.DND(
        state,
        path,
        action.payload.items,
        action.payload.data
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_ARRAY_DELETE_ITEM:
      blockArrayActions.deleteItem(
        state,
        path,
        action.payload.index,
        action.payload.data
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_ARRAY_ITEM_DELETE: {
      const newArrOfItems = action.payload.arrOfItems
      blockArrayActions.deleteItemCard(state, path, newArrOfItems)

      const [guid, , tabName] = path
      const errorKeyName = action.payload.errorKeyName
      validationActions.cleanBlockInvalidFields(guid, tabName, errorKeyName, state.invalidFields)

      return { ...state }
    }

    case ConfigurationsActionType.BLOCK_ARRAY_TABLE_MODIFY:
      blockArrayActions.tableModify(
        state,
        path,
        action.payload.index,
        action.payload.data
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_ARRAY_TABLE_REPLACE:
      blockArrayActions.replaceTable(state, path, action.payload.data)
      return { ...state }

    case ConfigurationsActionType.BLOCK_ARRAY_TABLE_IMPORT_CSV_DATA:
      blockArrayActions.importDataFromCsv(state, path, action.payload.data)
      return { ...state }

    case ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_TOP:
      blockArrayActions.addItem(
        state,
        path,
        action.payload.data,
        1,
        ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_TOP
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_BOTTOM:
      blockArrayActions.addItem(
        state,
        path,
        action.payload.data,
        1,
        ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_BOTTOM
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_TO_BEGINNING:
      blockArrayActions.addItem(
        state,
        path,
        action.payload.data,
        1,
        ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_TO_BEGINNING
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_TO_END:
      blockArrayActions.addItem(
        state,
        path,
        action.payload.data,
        1,
        ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_TO_END
      )
      return { ...state }

    case ConfigurationsActionType.BLOCK_ARRAY_CARD_DUPLICATE:
      blockArrayActions.addItem(
        state,
        path,
        action.payload.data,
        1,
        ConfigurationsActionType.BLOCK_ARRAY_CARD_DUPLICATE
      )
      return { ...state }

      // Note: Add 100 cards at once for testing
    case ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_100:
      blockArrayActions.addItem(
        state,
        path,
        action.payload.data,
        1,
        ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_100
      )
      return { ...state }

    case ConfigurationsActionType.REFRESH_RULES:
      blockArrayActions.refreshRules(state, path)
      return { ...state }

    case ConfigurationsActionType.APPLY_RULES_PARTIAL_UPDATE: {
      const { path, data } = action.payload
      blockArrayActions.updateRulesPartial(state, path, data)
      return { ...state }
    }

    case ConfigurationsActionType.SET_RULES_UPDATING: {
      const elementIds = action.payload as string []
      const rulesUpdating = elementIds.reduce((acc: any, cur:string) => {
        acc[cur] = true
        return acc
      }, state.rulesUpdating)
      return { ...state, rulesUpdating: { ...rulesUpdating }}
    }

    case ConfigurationsActionType.SET_RULES_UPDATING_ERROR:{
      const elementIds = action.payload as string[]
      const rulesUpdating = elementIds.reduce((acc: any, cur: string) => {
        acc[cur] = false
        return acc
      }, state.rulesUpdating)
      return { ...state, rulesUpdating: { ...rulesUpdating }}
    }

    case ConfigurationsActionType.SET_RULES_UPDATING_SUCCESS:{
      const elementIds = action.payload as string[]
      elementIds.forEach(elementId => {
        delete state.rulesUpdating[elementId]
      })
      return { ...state, rulesUpdating: { ...state.rulesUpdating } }
    }

    case ConfigurationsActionType.EMPTY_CONFIG_INIT:
      return {
        ...state,
        productsConfigurations: {
          ...state.productsConfigurations,
          [action.payload.guid]: {
            ...state.productsConfigurations[action.payload.guid],
            configuration: {
              ...state.productsConfigurations[action.payload.guid]
                .configuration,
              [action.payload.name]: action.payload.data,
            },
          },
        },
      }

    case ConfigurationsActionType.INIT_EMPTY_DATA:
      initEmptyDataAction(
        state,
        path,
        action.payload.name,
        action.payload.schema,
        action.payload?.type
      )
      return { ...state }

    case ConfigurationsActionType.START_TESTS:
      return {
        ...state,
        isTesting: true,
      }

    case ConfigurationsActionType.END_TESTS:
      return {
        ...state,
        isTesting: false,
      }

    case ConfigurationsActionType.SET_NEW_RULE_NAME:
      inputActions.ruleNameModify(state, path, action.payload.ruleName)
      return { ...state }

    case ConfigurationsActionType.CHANGE_RULE_STATE:
      inputActions.changeRuleState(state, path, action.payload.isRuleActive)
      return { ...state }

    case ConfigurationsActionType.UNSAVED_CHANGES:{
      const { guid } = action.payload
      const noChanges = _isEqual(
          state.productsConfigurations[guid],
          state.productsConfigurationsDefault[guid]
      )

      return {
        ...state,
        unsaved: { ...state.unsaved, [guid]: !noChanges },
      }
    }

    default:
      return state
  }
}
