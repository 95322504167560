import { Button, Form, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import React, { ChangeEvent, useState } from 'react'
import { hideModal } from '../../../store/actions/modalActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import style from './CsvModal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv'
import Papa from 'papaparse'
import { ConfigurationsActionType } from '../../../types/configurations'
import { SvgIcon } from '../../SvgIcon/SvgIcon'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation'
import { checkUnsavedChanges } from '../../../store/actions/configurationActions'
import { throwSuccessMessage } from '../../../utils/Errors'

export interface CsvDataImporterArgs {
    dataFromCsv: Record<string, string>[]
    setError: (value: {
        message: string
        values?: Record<string, string>
    }) => void
    setErrPosition: (value: { column?: string; row?: number }) => void
    setData: (value: any[]) => void
}

export interface CsvModalData {
    path: string[]
    objectSchema: any
    onImportFromCsv: (args: CsvDataImporterArgs) => void
}

export const CsvModal = () => {
    const dispatch = useAppDispatch()
    const [error, setError] = useState<null | {
        message: string
        values?: Record<string, string>
    }>(null)
    const [errPosition, setErrPosition] = useState<{column?: string, row?: number}>({})
    const [fileData, setFileData] = useState<any[]>([])
    const { data } = useTypedSelector<{ data: CsvModalData}>((store) => store.modal)

    const handleClose = (): void => {
        dispatch(hideModal())
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setError(null)
        setErrPosition({})
        setFileData([])
        const transformData = (value: any) => value === '' || value === 'null' ? null : value

        // @ts-ignore
        Papa.parse(event.target?.files?.[0], {
            header: true,
            skipEmptyLines: 'greedy',
            transform: transformData,
            complete: (result: any) => {
                const fileFields = result.meta?.fields

                if (!fileFields?.length) {
                    setError({ message: 'errors.validation.csvFileFields' })
                    return
                }

                if (fileFields.length) {
                    const objectSchemaKeys = Object.keys(
                        data.objectSchema
                    ).filter((field: string) => field !== 'element_id')

                    const hasAllFields = objectSchemaKeys.every(
                        (key: string) => !!fileFields.includes(key)
                    )
                    if (!hasAllFields) {
                        setError({ message: 'errors.validation.csvFileFields' })
                        return
                    }
                }

                data.onImportFromCsv({
                    dataFromCsv: result.data,
                    setError,
                    setErrPosition,
                    setData: setFileData
                })
            },
        })
    }

    const handleOnSubmit = () => {
        dispatch({
            type: ConfigurationsActionType.BLOCK_ARRAY_TABLE_IMPORT_CSV_DATA,
            payload: { path: data.path, data: fileData },
        })
        dispatch(checkUnsavedChanges(data.path[0]))
        setTimeout(() => {
            dispatch(hideModal())
            throwSuccessMessage('Successfully imported. Press Apply to save changes')
        }, 0)
    }

    return (
        <>
            <div className="bg-blur" />
            <Modal
                centered
                show={true}
                onHide={handleClose}
            >
                <Modal.Header
                    className="px-4 py-3"
                    closeButton
                >
                    <Modal.Title>
                        <FormattedMessage id="modals.csv.importTitle" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-4">
                    <div className={`${style.modalBody} app-color-default`}>
                        <span className={style.warning}>
                            <SvgIcon icon={faTriangleExclamation}/>
                            <FormattedMessage id={'modals.csv.emptyLineWarning'} />
                        </span>
                        <FontAwesomeIcon
                            className={style.csvIcon}
                            icon={faFileCsv}
                        />
                        <Form.Control
                            className="w-75 app-color-default"
                            type="file"
                            size="sm"
                            accept=".csv"
                            onChange={handleChange}
                            isInvalid={!!error}
                        />
                        {error && (
                            <Form.Control.Feedback
                                className="w-75 text-center"
                                type="invalid"
                            >
                                <FormattedMessage
                                    id={error.message}
                                    values={error?.values}
                                />
                                {(errPosition.column || errPosition.row) &&
                                    ` at column "${errPosition.column}" row "${errPosition.row}"`}
                            </Form.Control.Feedback>
                        )}
                    </div>
                </Modal.Body>
                <div>
                    <Modal.Footer className="px-4 py-3">
                        <Button
                            className="app-btn-main"
                            variant="secondary"
                            type="submit"
                            onClick={handleClose}
                        >
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button
                            variant="primary"
                            className="app-btn-main app-btn-apply me-0"
                            type="submit"
                            onClick={handleOnSubmit}
                            disabled={!fileData.length}
                        >
                            <FormattedMessage id="buttons.import" />
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    )
}
