import { Button, ListGroup } from 'react-bootstrap'

export const ErrorPage = () => {
    const handleRefresh = () => {
        window?.sessionStorage.clear()
        window?.location.reload()
    }

    return (
        <main className="app-bg-primary vh-100 d-flex flex-column gap-4 align-items-center justify-content-center">
            <h3>Unexpected error occurred</h3>
            <h6 className="font-w">
                Please refresh the page by clicking the button below
            </h6>
            <Button
                className="app-btn-main app-btn-apply mb-3"
                onClick={handleRefresh}
            >
                Refresh
            </Button>
            <div>If page refresh does not help please try the following</div>
            <ListGroup>
                <ListGroup.Item>Open app in another browser tab</ListGroup.Item>
                <ListGroup.Item>
                    Open app in another browser window
                </ListGroup.Item>
                <ListGroup.Item>Sign out and sign back in</ListGroup.Item>
            </ListGroup>
            <p>If the issue persists contact out customer support please</p>
        </main>
    )
}
