import { LogsActions } from '../types/logs'
import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'
import { logOnSignalRMethodTrigger } from './lib/logOnSignalRMethodTrigger'
import { SignalRMethods } from './lib/SignalRMethods'

export const receiveLiveLogs = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    connection.on(SignalRMethods.LOG_BATCH, (logs, productGuid) => {
        logOnSignalRMethodTrigger(SignalRMethods.LOG_BATCH, logs, productGuid)
        if (productGuid && logs && Array.isArray(logs)) {
            dispatch({
                type: LogsActions.NEW_LIVE_LOGS,
                payload: { productGuid, liveLogs: logs },
            })
        }
    })
}