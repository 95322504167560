import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'
import { receiveLiveLogs } from './receiveProductLogs'
import { receiveNotifications } from './receiveNotifications'
import { receiveProductStatus } from './receiveProductStatus'
import { receiveNewProduct } from './receiveNewProduct'
import { receiveActionUpdate } from './receiveActionUpdate'

export const initiateSignalRMethods = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    receiveLiveLogs(connection, dispatch)
    receiveNotifications(connection, dispatch)
    receiveProductStatus(connection, dispatch)
    receiveNewProduct(connection, dispatch)
    receiveActionUpdate(connection, dispatch)
}