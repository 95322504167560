export const getProductTime = (offset: number) => {
    if (offset === null || offset === undefined) return

    const currentDate = new Date()
    const currentUtcOffsetMilliseconds = currentDate.getTimezoneOffset() * 60 * 1000
    const utcTime = currentDate.getTime() + currentUtcOffsetMilliseconds

    const offsetInMilliseconds = offset * 60 * 1000

    return new Date(utcTime + offsetInMilliseconds).toLocaleString()
}