import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'
import { IProduct } from '../types/product'
import { fetchProducts } from '../store/actions/products/productsActions'
import { logOnSignalRMethodTrigger } from './lib/logOnSignalRMethodTrigger'
import { SignalRMethods } from './lib/SignalRMethods'

export const receiveNewProduct = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    connection.on(SignalRMethods.NEW_PRODUCT, (product: IProduct) => {
        logOnSignalRMethodTrigger(SignalRMethods.NEW_PRODUCT, product)
        if (!product?.guid) {
            return console.error('Invalid product received')
        }
        dispatch(fetchProducts())
    })
}
