import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
    selectCoreSettingsState,
    selectProductCoreSettings,
} from '../../store/selectors/coreSettingsSelectors'
import Loader from '../Loader/Loader'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { fetchProductCoreSettings } from '../../store/actions/coreSettingsActions'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { CoreSettingsFields } from './CoreSettingsFields/CoreSettingsFields'
import { ProductTime } from '../ProductTime/ProductTime'

interface CoreSettingsProps {
    guid: string
}

export const CoreSettings = (props: CoreSettingsProps) => {
    const { guid } = props
    const dispatch = useAppDispatch()

    const { isLoading, error } = useTypedSelector(selectCoreSettingsState)
    const productCoreSettings = useTypedSelector(
        selectProductCoreSettings(guid)
    )

    useEffect(() => {
        if (!productCoreSettings) {
            dispatch(fetchProductCoreSettings(guid))
        }
    }, [])

    if (isLoading)
        return (
            <div className="vh-full d-flex justify-content-center">
                <Loader />
            </div>
        )

    if (error)
        return (
            <div className={`m-4 px-4 py-3 fs-5 error-in-frame`}>
                <FormattedMessage id={error} />
            </div>
        )

    return (
        <>
            <div className="app-light-color pt-3  d-flex justify-content-between">
                <div className="px-4 pt-2 pb-3 nav-dark">GENERAL SETTINGS</div>
                <ProductTime guid={guid} className="px-4 pt-2 pb-3 nav-dark" />
            </div>
            <CoreSettingsFields
                guid={guid}
                productCoreSettings={productCoreSettings}
            />
        </>
    )
}
