import { Dropdown } from 'react-bootstrap'
import './BDropdown.module.scss'
import { ButtonWithTooltip } from '../../buttons/ButtonWithTooltip'
import React, { ReactNode } from 'react'
import { faEllipsisH, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { AppDropdownItem } from '../AppDropdown/AppDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'

export interface BDropdownProps {
    tooltip: string
    dropdownItems: AppDropdownItem[]
    dropdownToggle?: ReactNode
    icon?: IconDefinition
    className?: string
    hasHr?: boolean
    isDisabled?: boolean
}

export const BDropdown = (props: BDropdownProps) => {
    const {
        tooltip,
        dropdownItems,
        dropdownToggle,
        icon,
        className,
        hasHr,
        isDisabled,
    } = props

    const dropdownButtons = dropdownItems.map((item) => (
        <Dropdown.Item
            key={item.name}
            disabled={item.disabled}
            onClick={item.action}
        >
            {!!item.icon && (
                <FontAwesomeIcon
                    className={`${item.className ? item.className : ''} me-2`}
                    icon={item.icon!}
                />
            )}
            <FormattedMessage id={item.name} />
        </Dropdown.Item>
    ))

    if (hasHr) {
        dropdownButtons.splice(
            2,
            0,
            <hr
                key="hr"
                className="mx-2 my-1"
            />
        )
    }

    return (
        <Dropdown
            className={className}
            style={{ opacity: isDisabled ? 0.5 : 1 }}
        >
            <ButtonWithTooltip tooltip={tooltip}>
                <Dropdown.Toggle
                    style={{ boxShadow: 'unset', fontSize: 'unset' }}
                    className="bg-transparent border-0 px-2 py-1"
                    disabled={isDisabled}
                >
                    {dropdownToggle ? (
                        dropdownToggle
                    ) : (
                        <FontAwesomeIcon icon={icon ? icon : faEllipsisH} />
                    )}
                </Dropdown.Toggle>
            </ButtonWithTooltip>
            <Dropdown.Menu>{dropdownButtons}</Dropdown.Menu>
        </Dropdown>
    )
}
